import { Snackbar } from "@material-ui/core";
import React from "react";

export default class CurbsideOrderShareLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    handleShareButton = async () => {
        let shareTextBody = this.props.curbsidePickupData && this.props.curbsidePickupData.otherDetailsData && this.props.curbsidePickupData.otherDetailsData.shareTextBody;
        try {
            await navigator.share({ text: shareTextBody, url: window.location.href });
        } catch (err) {
            navigator.clipboard.writeText(window.location.href);
            this.setState({ open: true });
            console.log("Error", err);
        }
    }

    // For share text body 
    findAndWrapLinksInShareTextBody = (shareTextBody) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const parts = shareTextBody.split(urlRegex);

        return parts.map((part, index) => {
            if (urlRegex.test(part)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#2b8bff", textDecoration: "underline" }}
                    >
                        {part}
                    </a>
                );
            }
            return part;
        });
    }
    render() {
        let shareTextBody = this.props.curbsidePickupData?.otherDetailsData?.shareTextBody;
        if (shareTextBody) {
            return (
                <div>
                    <div className="share-link-container">
                        {/* <h4>{}</h4> */}
                        <h4>{this.findAndWrapLinksInShareTextBody(shareTextBody)}</h4>
                        <button className="order-share-button" onClick={this.handleShareButton}>{containerConstants.formatString(containerConstants.ShareThisLink)}</button>
                        <img src={require("../../../images/banner/share-link-banner-staff.svg")} alt="" />
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={this.state.open}
                            autoHideDuration={3500}
                            onClose={() => this.setState({ open: false })}
                            message={containerConstants.formatString(containerConstants.CopiedtoClipboard)}>
                        </Snackbar>
                    </div>
                </div>
            )
        }
        return <></>
    }
}


