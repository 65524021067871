const englishStrings = {
    YourShipmentHasBeenDelivered:'Your shipment has been delivered',
    DeliveredBy:'Delivered By',
    RateYourExperience:'Rate Your Experience',
    ThankYouForYourValuableFeedback:'Thank you for your valuable feedback',
    YourFeedbackWillContinuallyHelpUsImproveOurServices:'Your feedback will continually help us improve our services',
    DeliveryFailed:'Delivery Failed',
    AddDeliveryPreferences:'Add Delivery Preferences',
    YourDeliveryPreferenceAreSavedSuccessFully:'Your delivery preference are saved successFully',
    WeAreUnableToTrackYourShipmentRightNow:'We are unable to track your shipment right now',
    PleaseUpdateLocationInsideCircle:'Please update location inside circle',
    Ok:'OK',
    Update:'Update',
    PickCurrentLocation:'Pick current location',
    SearchFor:'Search for',
    ThisSchedulingLinkHasExpired:'This scheduling link has expired',
    WeWillShareANewLinkWithYouShortly:'We will share a new link with you shortly',
    UhHo:'Uh ho!',
    NeedHelp:'NEED HELP?',
    CallSupport:'Call Support',
    EmailSupport:'Email Support',
    HelpLineLink: 'Helpline Link',
    DeliveryAt: 'Delivery At',
    DeliveredAt: 'Delivered At',
    ContactNo: 'Contact no',
    TrackOnMap:'Track on Map',
    MessageToExecutive:'Message to Executive',
    Cancel:'Cancel',
    Send:'Send',
    Executive:'Executive',
    Comments:'Comments (optional)',
    SubmitFeedback:'SUBMIT FEEDBACK',
    TrackingNo:'Tracking No',
    Details:'Details',
    Characters:'Characters',
    Updating:'updating.....',
    OopsSomethingWentWrongPleaseTryAfterSomeTime:'Oops! Something went wrong, please try after some time.',
    NotListedAboveSendACustomMessage:'Not listed above, send a custom message',
    ChangeDeliveryLocation:'Change Delivery location',
    AttemptedBy:'Attempted by',
    DeliveredTo:'Delivered To',
    SendMessage:'SEND MESSAGE',
    VIEW_DETAILS:'View details',
    SAVE_LOCATION:'SAVE LOCATION',
    LOCATION:'location',
    SEARCH_ADDRESS:'Search Address',
    OpenHours:'Open Hours',
    Note:'Note',
    Address:'Address',
    Nearest: 'Nearest',
    ContactNumber:'Contact Number',
    FAQS:'FAQs',
    HowManyTimesCanIChangeMyPickUpPoint: 'How many times can I change my pick up point?',
    YouCanChangeItOnly: 'You can change it only',
    GoBack:'Go Back',
    SetAsPickupStore:'Set as Pickup Store',
    MapView:'Map View',
    SearchForPickupPointNearYou:'Search for Pickup point near you',
    Arriving:'Arriving',
    Pickup: 'Pickup',
    LiveTrackingWillStart:'Live tracking will start',
    DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'Driver’s tracker will get activated once they are on your way',
    TrackerTimeline:'Tracker Timeline',
    PM:'PM',
    AM:'AM',
    TodayAt:'today at',
    TomorrowAt:'tomorrow at',
    Today:'today',
    Tomorrow:'tomorrow',
    On:'on',
    In:'in',
    Soon:'soon',
    Min:'min',
    Mins:'mins',
    Items:'ITEMS',
    ViewDetails: 'View Details',
    DeliveryInformation:'Delivery Information',
    TrackingHistory:'Tracking History',
    StayConnected:'Stay Connected',
    YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'You have already attempted to make a payment in last few minutes. Do you want to cancel previous transactions',
    No: 'NO',
    yes:'YES',
    YourPackageWillBeDeliveredBy : 'Your Package will be delivered by',
    YourPackageWillBePickedBy: 'Your Package will be picked by',
    OrderDetails: 'Order Details',
    OrderInformation:'Order Information',
    LastAttempted:'Last Attempted',
    DeliveryBy:'Delivery By',
    Pickuppointisupdated:'Pickup Point is updated',
    MsgToFePageHeader:'Send Delivery Instructions',
    MsgToFeSupportingText:'What do you want to tell the driver?',
    AddYourMsgHere:'Add your message here',
    InstructionsSentSuccessfully:'Instructions Sent Successfully',
    SomethingWentWrongPlsTryAgain:'Something went wrong, please try again.',
    times:"times",
    TrackMovementInRealTime:"Track Movement in Real-Time",
    Hours:"hours",
    Hour:"hour",
    WriteYourFeedbackOptional:"Write your feedback (Optional)",
    ReturnOrder: "Return Order",
    TheLinkYouAreTryingToOpenHasExpired: "The link you are trying to open has expired",
    SelectAnAvailableTimeSlot:"Select an available time slot",
    January:"JANUARY",
    February:"FEBRUARY",
    March :"MARCH",
    April:"APRIL",
    May:"MAY",
    June:"JUNE",
    July:"JULY",
    August:"AUGUST",
    September:"SEPTEMBER",
    October:"OCTOBER",
    November:"NOVEMBER",
    December:"DECEMBER",
    Monday:"MONDAY",
    Tuesday:"TUESDAY",
    Wednesday:"WEDNESDAY",
    Thursday:"THURSDAY",
    Friday:"FRIDAY",
    Saturday:"SATURDAY",
    Sunday:"SUNDAY",
    ViewTimings: "View Timings",
    HideTimings: "Hide Timings",
    PleaseAnswerAllMandatoryQuestions:"Please answer all mandatory questions",
    ShipmentOf: "Shipment%of",
    OtherShipments: "Other Shipments",
    OrderInformation:"Order Information",
    OR:"or",
    TrackShipment:"Track shipment",
    TrackingDescription:"Enter the details below to get details of the your shipment",
    Continue:"Continue",
    InvalidCredentials:"Invalid credentials. Please try again",
    ITEM: 'item',
    ITEMS: 'item(s)',
    OrderNo:'Order No.',
    CurrentStatus:"Current Status",
    PickupTime:"Pickup Time",
    StoreLocation:"Store Location",
    LeaveBy:"Leave By",
    toReachByScheduledTime:"to reach by scheduled time",
    ShareThisLink:"Share this Link",
    ETAtime:"ETA time",
    ParkingInstructions:"Parking Instructions",
    PR1_heading:"Find a Spot",
    PR2_heading:"Park Safely",
    PR3_heading:"Secure and Exit",
    PR1_description:"Look for a marked parking space; ensure it's not restricted or reserved.",
    PR2_description:" Align your vehicle within the lines and leave enough space for others.",
    PR3_description:"Turn off the engine, engage the handbrake, lock the car, and take your belongings.",
    willBringYourOrderShortly:"will bring your order shortly.",
    PODImage:"POD Image",
    CopiedtoClipboard:"Copied to Clipboard",
    

}  
export default  englishStrings; 