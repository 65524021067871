const urduStrings={
    YourShipmentHasBeenDelivered:'آپ کا سامان پہنچا دیا گیا ہے',
    DeliveredBy:'ترسیل کنندہ',
    RateYourExperience:'اپنا تجربہ بتائیں',
    ThankYouForYourValuableFeedback:'آپ کی قیمتی رائے کیلئے شکریہ',
    YourFeedbackWillContinuallyHelpUsImproveOurServices:'آپ کی تجاویز ہماری خدمات کو بہتر کرنے میں مستقل مدد کرے گی',
    DeliveryFailed:'ڈیلیوری ناکام ہو گئی',
    AddDeliveryPreferences:'ڈیلیوری کی ترجیحات شامل کریں',
    YourDeliveryPreferenceAreSavedSuccessFully:'آپ کی ڈیلیوری کی ترجیحات کامیابی کے ساتھ محفوظ ہو گئیں',
    WeAreUnableToTrackYourShipmentRightNow:'ہم فی الحال آپ کا سامان ٹریک کرنے سے قاصر ہیں',
    PleaseUpdateLocationInsideCircle:'سرکل کے اندر لوکیشن اپڈیٹ کریں',
    Ok:'ٹھیک ہے',
    Update:'اپڈیٹ کریں',
    PickCurrentLocation:'حالیہ لوکیشن متنخب کریں',
    SearchFor:'تلاش برائے',
    ThisSchedulingLinkHasExpired:'اس شیڈولنگ لنک کی میعاد ختم ہوگئی ہے',
    WeWillShareANewLinkWithYouShortly:'ہم جلد ہی آپ کے ساتھ نیا لنک شیئر کریں گے',
    UhHo:'اُہ ہو!',
    NeedHelp:'مدد کی ضرورت ہے؟',
    CallSupport:'سپورٹ کیلئے کال کریں',
    EmailSupport:'سپورٹ کیلئے ای میل کریں',
    DeliveryAt:'ڈیلیوری علیٰ',
    DeliveredAt: 'یہاں ڈیلیور کیا گيا ',
    ContactNo:'رابطہ نمبر',
    TrackOnMap:'نقشہ پر ٹریک کریں',
    MessageToExecutive:'ایگزیکٹو کو پیغام',
    Cancel:'رد کریں',
    Send:'بھیجیں',
    Executive:'ایگزیکٹو',
    Comments:'تجاویز(اختیاری)',
    SubmitFeedback:'فیڈبیک جمع کریں',
    TrackingNo:'ٹریکنگ نمبر',
    Details:'تفصیلات',
    Characters:'کردار',
    Updating:'اپ ڈیٹ کیا جا رہا ہے.....',
    OopsSomethingWentWrongPleaseTryAfterSomeTime:'افوہ! کچھ غلط ہو گیا ہے، براہ مہربانی کچھ دیر بعد کوشش کریں',
    NotListedAboveSendACustomMessage:'اوپر شامل فہرست نہیں، ایک مخصوص پیغام بھیجیں',
    ChangeDeliveryLocation:'ڈلیوری کا محل وقوع تبدیل کریں',
    AttemptedBy:'اس کی طرف سے کوشش کی گئی',
    DeliveredTo:'اس کو ڈلیور کیا گیا/کی گئی',
    SendMessage:'پیغام بھیجیں',
    VIEW_DETAILS:'عرض التفاصيل',
    SAVE_LOCATION:'حفظ الموقع',
    LOCATION:'الموقع',
    SEARCH_ADDRESS:'عنوان البحث',
    OpenHours:'کُھلنے کے اوقات',
    Note:'نوٹ',
    Address:'پتہ',
    Nearest: 'قریب ترین',
    ContactNumber:'رابطہ نمبر',
    FAQS:'عمومی سوالنامہ',
    HowManyTimesCanIChangeMyPickUpPoint: 'میں کتنی مرتبہ اپنا پک اپ پوائنٹ تبدیل کر سکتا/سکتی ہوں؟',
    YouCanChangeItOnly: 'آپ اسے صرف تبدیل کر سکتے ہیں',
    GoBack:'واپس جائیں',
    SetAsPickupStore:'پک اپ سٹور سیٹ کریں',
    MapView:'نقشہ کا نظارہ',
    SearchForPickupPointNearYou:'اپنے قریی پک اپ پوائنٹ تلاش کریں',
    Arriving:'پہنچ رہا ہے',
    LiveTrackingWillStart:'براہ راست ٹریکنگ شروع ہو گی',
    DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'ایک مرتبہ جب وہ راستے میں ہو تو ڈرائیور کا ٹریکر چالو ہو جائے گا',
    TrackerTimeline:'ٹریکر کی ٹائم لائن',
    PM:'شام',
    AM:'صبح',
    TodayAt:'آج اس وقت',
    TomorrowAt:'کل اس وقت',
    Today:'آج',
    Tomorrow:'کال',
    On:'پر',
    In:'میں',
    Soon:'جلد ہی',
    Min:'منٹ',
    Mins:'منٹس',
    Items:'اشیاء',
    ViewDetails: 'تفصیلات دیکھیں',
    DeliveryInformation:'ڈلیوری کی معلومات',
    TrackingHistory:'ٹریکنگ کی سابقات',
    StayConnected:'جڑے رہیں',
    YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'آپ نے پہلے ہی گزشتہ چند منٹوں میں ادائیگی کرنے کی کوشش کی ہے۔ کیا آپ سابقہ ٹرانزیکشنوں کو منسوخ کرنا چاہتے ہیں',
    No: 'نہیں',
    yes:'ہاں',
    YourPackageWillBeDeliveredBy : 'آپ کا پیکیج اس کی طرف سے ڈلیور کر دیا جائے گا',
    OrderDetails: 'آرڈر کی تفصیلات',
    OrderInformation:'آرڈر کی معلومات',
    LastAttempted:'آخری بار کوشش کی گئی',
    DeliveryBy:'ڈیلیوری بذریعہ',
    Pickuppointisupdated:'پک اپ پوائنٹ اپ ڈیٹ ہوگیا',
    MsgToFePageHeader:'ڈیلیوری سے متعلق ہدایات بھیجیں',
    MsgToFeSupportingText:'آپ ڈرائيور سے کیا کہنا چاہتے ہیں؟',
    AddYourMsgHere:'یہاں پر اپنا پیغام شامل کریں',
    InstructionsSentSuccessfully:'پیغام کامیابی کے ساتھ بھیج دیا گيا',
    SomethingWentWrongPlsTryAgain:'کچھ غلط ہوگیا، مہربانی کرکے دوبارہ کوشش کریں۔.',
    times:"مرتبہ",
    TrackMovementInRealTime:"ریئل ٹائم میں حرکت کا پتہ لگائیں",
    Hours:"hours",
    Hour:"hour",
    WriteYourFeedbackOptional:"اپنی رائے لکھیں (اختیاری)    ",
    ReturnOrder: "Return Order",
    TheLinkYouAreTryingToOpenHasExpired: "آپ جس لنک کو کھولنے کی کوشش کر رہے ہیں اس کی میعاد ختم ہو گئی ہے۔    ",
    SelectAnAvailableTimeSlot:"Select an available time slot",
    January:"JANUARY",
    February:"FEBRUARY",
    March :"MARCH",
    April:"APRIL",
    May:"MAY",
    June:"JUNE",
    July:"JULY",
    August:"AUGUST",
    September:"SEPTEMBER",
    October:"OCTOBER",
    November:"NOVEMBER",
    December:"DECEMBER",
    Monday:"MONDAY",
    Tuesday:"TUESDAY",
    Wednesday:"WEDNESDAY",
    Thursday:"THURSDAY",
    Friday:"FRIDAY",
    Saturday:"SATURDAY",
    Sunday:"SUNDAY",
    February:"FEBRUARY",
    ViewTimings: "View Timings",
    HideTimings: "Hide Timings",
    PleaseAnswerAllMandatoryQuestions:"براہ کرم تمام ضروری سوالات کے جوابات دیں۔",
    HelpLineLink: 'Helpline Link',
    ShipmentOf: "ترسیل%کا",
    OtherShipments: "دیگر شپمنٹ",
    OrderInformation:"Order Information",
    OR: "یا",
    TrackShipment:"شپمنٹ ٹریک کریں",
    TrackingDescription:"اپنی شپمنٹ کی تفصیلات حاصل کرنے کے لئے نیچے دی گئی تفصیلات درج کریں",
    Continue:"جاری رکھیں",
    InvalidCredentials:"غلط اسناد۔ براہ کرم دوبارہ کوشش کریں",
    ITEM: 'آئٹم',
    ITEMS: 'آئٹم(ز)',
    OrderNo:"آرڈر نمبر",
    CurrentStatus:"موجودہ حالت",
PickupTime:"پک اپ کا وقت",
StoreLocation:"Store Location",
LeaveBy:"Leave By",
toReachByScheduledTime:"to reach by scheduled time",
ShareThisLink:"Share this Link",
ETAtime:"ETA time",
ParkingInstructions:"Parking instructions",
PR1_heading:"Find a Spot",
PR2_heading:"Park Safely",
PR3_heading:"Secure and Exit",
PR1_description:"Look for a marked parking space; ensure it's not restricted or reserved.",
PR2_description:" Align your vehicle within the lines and leave enough space for others.",
PR3_description:"Turn off the engine, engage the handbrake, lock the car, and take your belongings.",
willBringYourOrderShortly:"will bring your order shortly.",
PODImage:"POD Image",
CopiedtoClipboard:"Copied to Clipboard",
}
export default urduStrings;