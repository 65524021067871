import React from "react";
import MarketingAsset from "../components/MarketingAsset";
import Header from "../../../components/Header";
import CurbsideOrderShareLink from "../components/CurbsideOrderShareLink";
import SocialMedia from "../../../components/SocialMedia";
import CurbsidePickupConstants, { curbsidePageFlowTypes } from "../constants/CurbsidePickupConstants";
import { Box, Modal } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty } from "lodash";
import CurbsideNeedHelpDrawer from "../components/CurbsideNeedHelpDrawer";
import { Button, Card, Typography } from "antd";
import EmbedTracking from "../../LiveTracking/EmbedTracking";
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import DirectionsOutlinedIcon from '@material-ui/icons/DirectionsOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { formatDate, formatTimeRange, formateText } from "./config";
import { CarOutlined, EyeOutlined, ShoppingOutlined, WarningOutlined } from '@ant-design/icons';

const { Text } = Typography;

class CurbSideMobileView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showNeedHelpDrawer: false,
            modalOpen: false,
            parkingInstructionsModal: false
        }

    }
    setHelpModel = (show) => {
        this.setState({ showNeedHelpDrawer: show });
    }

    getNeedHelp = () => {
        let helpSectionText, callSupport, emailSupport;
        if (this.props.trackingDetails && this.props.trackingDetails.type === "CURBSIDE_PICKUP" && this.props.trackingDetails.customerInteractionThemeSettingsDTO && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO)) {
            helpSectionText = this.props.trackingDetails.customerInteractionThemeSettingsDTO.helpSectionText;
            helpSectionText = helpSectionText ? helpSectionText : containerConstants.formatString(containerConstants.NeedHelp);
            callSupport = this.props.trackingDetails.customerInteractionThemeSettingsDTO.supportContactNumber;
            emailSupport = this.props.trackingDetails.customerInteractionThemeSettingsDTO.supportEmail;
        }

        if (callSupport || emailSupport) {
            return {
                helpSectionText,
                callSupport,
                emailSupport
            }
        }
        return undefined;
    }

    changeScreenToPickupSchedule = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.TIME_SLOT, this.changeScreenToPreCheckIn);
    }

    changeScreenToPreCheckIn = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.PRE_CHECK_IN, null);
    }

    changeScreenToCheckIn = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.CHECK_IN, this.changeScreenToPreCheckIn);
    }

    changeScreenToOrderDetails = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.ORDER_DETAILS, this.changeScreenToPreCheckIn);
    }

    getPickupDataWithSlot = () => {
        const pickupData = { label: "", date: "", time: "" };
        const preCheckInData = this.props.curbsidePickupData?.preCheckInData;
        if (preCheckInData && preCheckInData.pickupScheduleDateTimeAttribute) {
            if (preCheckInData.pickupScheduleDateTimeLabel) {
                pickupData["label"] = preCheckInData.pickupScheduleDateTimeLabel;
            }
            pickupData["date"] = formatDate(preCheckInData.pickupScheduleDateTimeAttribute);
            pickupData["time"] = formatTimeRange(preCheckInData.pickupScheduleDateTimeAttribute, preCheckInData.pickupScheduleDateTimeMin, preCheckInData.pickupScheduleDateTimeMax);
            return pickupData;
        }
        return null;
    }
    handleButtonClick = (buttonConfig) => {
        if (buttonConfig.action == "FORM") {
            let processUpdateForm = JSON.parse(this.props.customerInteractionServiceDTO.processUpdateForm);
            const index = processUpdateForm.formList.findIndex(puf => puf.customerInteractionFormId == buttonConfig.form);
            let attribute = {}
            if (buttonConfig?.nextStatusCode) {
                attribute.key = this.props.curbsidePickupData?.statusAttributeCode;
                attribute.type = "String"
                attribute.typeId = 1;
                attribute.hide = true;
                attribute.value = buttonConfig?.nextStatusCode;
            }
            this.props.handleButtonAction(index, attribute);
        }
        else {
            let referenceNumber = this.props.customerInteractionServiceDTO?.referenceNumber;
            let processMasterCode = ""
            let processUpdateForm = this.props.customerInteractionServiceDTO?.processUpdateForm;
            if (processUpdateForm) {
                let parsedProcessUpdateForm = JSON.parse(processUpdateForm);
                processMasterCode = parsedProcessUpdateForm.processMasterCode;
            }
            let form = [];
            let attribute = {}
            attribute.key = this.props.curbsidePickupData?.statusAttributeCode;
            attribute.type = "String"
            attribute.typeId = 1
            attribute.value = buttonConfig?.nextStatusCode;
            form.push(attribute);
            this.props.updateFormData(form, processMasterCode, referenceNumber, this.props.curbsidePickupUrl, "form", "", "", "", "", "", "", "", "", "", "", "CURBSIDE_PICKUP", "", false);
            window.location.reload();
        }

    }
    //this commentd fucntion is actually tested in preCheckin 
    // updateStatusOnButtonClick=(button)=>{
    //         let referenceNumber =this.props.customerInteractionServiceDTO?.referenceNumber;
    //         let processMasterCode =""
    //         let processUpdateForm = this.props.customerInteractionServiceDTO?.processUpdateForm;
    //         if(processUpdateForm){
    //             let parsedProcessUpdateForm = JSON.parse(processUpdateForm);
    //             processMasterCode = parsedProcessUpdateForm.processMasterCode;
    //         }
    //         let form =[];
    //         let attribute ={}
    //         attribute.key = this.props.curbsidePickupData?.statusAttributeCode;
    //         attribute.type ="String"
    //         attribute.typeId = 1
    //         attribute.value=button?.nextStatus;
    //         form.push(attribute);
    //        this.props.updateFormData(form,processMasterCode,referenceNumber,this.props.curbsidePickupUrl, "form","","","","","","","","","","","CURBSIDE_PICKUP","",false);
    //        }
    renderButtons = () => {
        return this.props.buttonsConfig.map((buttonConfig) => {
            if(!isEmpty(buttonConfig.label)){
                return (
                    <button
                    className="submit-button"
                    onClick={() => { this.handleButtonClick(buttonConfig) }}
                    >{buttonConfig.label}</button>
                )
            }
        })
    }

    renderPickupData = () => {
        const pickupData = this.getPickupDataWithSlot();
        if (pickupData) {
            return (
                <div style={{ padding:"10px"}}>
                    <Text strong>{containerConstants.formatString(containerConstants.PickupTime)}</Text>
                    <br />
                    <Text>{pickupData.time}, {pickupData.date}</Text>
                </div>
            )
        }
    }



    renderParkingInstructions = () => {
        // let parkingInstructions = this.props.curbsidePickupData?.storeDetailsData?.parkingInstructions;
       
        let parkingInstructions = `${"1. <b>" + containerConstants.formatString(containerConstants.PR1_heading) + "</b>: " +
            containerConstants.formatString(containerConstants.PR1_description) + "\n" +
            "2. <b>" + containerConstants.formatString(containerConstants.PR2_heading) + "</b>: " +
            containerConstants.formatString(containerConstants.PR2_description) + "\n" +
            "3. <b>" + containerConstants.formatString(containerConstants.PR3_heading) + "</b>: " +
            containerConstants.formatString(containerConstants.PR3_description)}`;
        if (isEmpty(parkingInstructions)) {
            return;
        }
        let primaryColor = this.props.curbsidePickupData?.customerInteractionServiceDTO?.customerInteractionThemeSettingsDTO?.primaryBgColor || '#0000';
        let parkingInstructionsList = parkingInstructions.split("\n");
        return (
            <div className="c-s-parking-instruction">
                <h2 style={{ color: primaryColor, borderColor: primaryColor }}>{containerConstants.formatString(containerConstants.ParkingInstructions)}<WarningOutlined /></h2>
                {parkingInstructionsList.map((instructions) => {
                    let formatedInstruction = instructions.replace(/\s+/g, " ").trim();
                    return <p>{formateText(formatedInstruction)}</p>
                })}
            </div>
        )
    }
    getTimeToLeave = () => {
        const dateString = this.props.curbsidePickupData?.preCheckInData?.pickupScheduleDateTimeAttribute;
        const travelDuration = this.props.curbsidePickupData?.travelDuration;;
        if (dateString && travelDuration) {
            const match = travelDuration.match(/(?:(\d+)\s*hours?)?\s*(?:(\d+)\s*mins?)?/i);
            const hours = match[1] ? parseInt(match[1], 10) : 0;
            const minutes = match[2] ? parseInt(match[2], 10) : 0;
            let date = new Date(dateString);
            date.setHours(date.getHours() - hours);
            date.setMinutes(date.getMinutes() - minutes);
            return date.toTimeString().substring(0,5);
        }
        return null;
    }
    getActions = () => {
        if (this.props.screenType == "TRACKING") {
            return [
                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', gap: 6, padding: '0 10px', color: '#000' }} onClick={() => this.changeScreenToOrderDetails(curbsidePageFlowTypes.ORDER_DETAILS)}><EyeOutlined />{containerConstants.formatString(containerConstants.ViewDetails)}</div>,
                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', gap: 6, padding: '0 10px', color: '#000' }} onClick={() => { this.setState({ parkingInstructionsModal: true }) }}><CarOutlined /> {containerConstants.formatString(containerConstants.ParkingInstructions)}</div>,
            ];
        }
        return [
            <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', gap: 6, padding: '0 10px', color: '#000' }} onClick={() => this.changeScreenToOrderDetails(curbsidePageFlowTypes.ORDER_DETAILS)}><EyeOutlined />{containerConstants.formatString(containerConstants.ViewDetails)}</div>,
        ];
    }
    renderStatusValue = () => {
        const themeData = this.props.curbsidePickupData?.customerInteractionServiceDTO?.customerInteractionThemeSettingsDTO;
        return <div
            style={{ borderRadius: 20, padding: '5px 10px', fontSize: 12, backgroundColor: (themeData?.ctaColorWhite ? "#ffffff" : themeData?.secondaryBgColor), color: (themeData?.ctaFontColor ? themeData?.ctaFontColor : (themeData?.primaryTextColor ? themeData?.primaryTextColor : themeData?.primaryBgColor)) }}
        >
            {this.props.customerInteractionServiceDTO?.status || ''}
        </div>
    }
    openGoogleMap=()=>{
        const lat = this.props.trackingDetails.jobLat || 0;
        const lng = this.props.trackingDetails.jobLng || 0;
        const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
        window.open(googleMapsUrl, "_blank");
    }
    isShowExecutiveMsg = ()=>{
        if(this.props.buttonsConfig?.at(0)?.label){
            return false;
        }
        return true;
    }
    render() {
        const needHelp = this.getNeedHelp();
        const timeToLeave = this.getTimeToLeave();
        return (
            <div style={{ fontFamily: "lato" }}>
                <Header
                    logo={this.props.companyLogo}
                    curbsidePickup={true}
                    primaryBgColor={"primaryBgColor"}
                    primaryTextColor={"primaryTextColor"}
                    fontSize={"fontSize"}
                    fontFamily={"fontFamily"}
                    // passing device type true for moble view
                    deviceType={true}
                    needHelp={this.showNeedHelp}
                    setHelpModel={() => this.setHelpModel(true)}
                    helpSectionText={this.needHelp && needHelp.helpSectionText}
                >
                </Header>

                {this.showNeedHelp &&
                    <CurbsideNeedHelpDrawer
                        {...this.props}
                    />
                }

                <div className="curb-side-container">
                    <Card style={{ borderRadius: 8, width: "100%", overflow: 'hidden', borderColor: '#dcdcdc' }}
                        bodyStyle={{ padding: 0 }}
                        actions={this.getActions()}>
                        <header style={{ borderBottom: '1px solid #d3d3d3', padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ borderRadius: "50%", padding: "10px", marginRight: "10px", width: "40px", height: "40px", background: "#2929291c", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ShoppingOutlined />
                                </div>
                                <div>
                                    <Text strong>{containerConstants.formatString(containerConstants.OrderNo)}</Text>
                                    <br />
                                    <Text>{this.props.curbsidePickupData.storeDetailsData && this.props.curbsidePickupData.storeDetailsData.orderNumber}</Text>
                                </div>
                            </div>
                            <div>
                                {
                                    this.props.customerInteractionServiceDTO?.status &&
                                    this.renderStatusValue()
                                }

                            </div>
                        </header>
                        <div >
                            {
                                this.props.curbsidePickupData?.storeDetailsData?.addressLine1 &&
                                <div style={{ padding:"10px"}}>
                                    <Text strong>{containerConstants.formatString(containerConstants.StoreLocation)}</Text>
                                    <br />
                                    <Text>{this.props.curbsidePickupData?.storeDetailsData?.addressLine1}</Text>
                                </div>
                            }
                            {
                                this.props.screenType == "PRE_CHECK_IN" && this.renderPickupData()
                            }
                            {
                                this.props.screenType == "PRE_CHECK_IN" && timeToLeave &&
                                <div style={{ padding:"10px"}}>
                                    <Text strong>{containerConstants.formatString(containerConstants.LeaveBy)}</Text>
                                    <br />
                                    <Text>{timeToLeave} {containerConstants.formatString(containerConstants.toReachByScheduledTime)}</Text>
                                </div>
                            }
                            {
                                this.props.screenType == "TRACKING" &&
                                this.props.curbsidePickupData?.travelDuration && <div style={{ padding:"10px"}}>
                                    <Text strong>{containerConstants.formatString(containerConstants.ETAtime)}</Text>
                                    <br />
                                    <Text>{this.props.curbsidePickupData?.travelDuration}</Text>
                                </div>
                            }
                            {
                                (this.props.screenType == "POST_CHECK_IN" && this.isShowExecutiveMsg()) &&
                                <div style={{ textAlign: "center", margin: "10px" }}>
                                    <Text style={{ fontSize: 14, fontWeight: 400 }}>{this.props.curbsidePickupData?.postCheckInData?.deliveryStaff || containerConstants.formatString(containerConstants.Executive)} {containerConstants.formatString(containerConstants.willBringYourOrderShortly)}</Text>
                                </div>
                            }

                        </div>

                    </Card>
                    {
                        (this.props.screenType == "PRE_CHECK_IN" || this.props.screenType == "TRACKING") &&
                        <div className="c-s-map-card" onClick={this.openGoogleMap}>
                            <EmbedTracking />
                        </div>
                    }
                    <CurbsideOrderShareLink
                        curbsidePickupData={this.props.curbsidePickupData}
                        curbsidePickupUrl={this.props.curbsidePickupUrl}
                    />

                    {this.props.buttonsConfig && <div className="c-s-submit-btn">
                        {this.renderButtons()}
                    </div>}
                    <Modal
                        open={this.state.modalOpen}
                        onClose={() => this.setState({ 'modalOpen': false })}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >

                            <div className="modal-container" style={{ width: "65vw", background: "white", padding: "10px", borderRadius: "8px" }}>
                                <p style={{ textAlign: "center", padding: "20px" }}>I have checked and received 13 items?</p>
                                <div className="m-c-buttons" style={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <Button style={{ width: "45%" }}>Cancel</Button>
                                    <Button style={{ color: "white", width: "45%", background: "#3cbaff" }}>Confirm</Button>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                    <Modal
                        open={this.state.parkingInstructionsModal}
                        onClose={() => this.setState({ parkingInstructionsModal: false })}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            {this.renderParkingInstructions()}
                        </Box>
                    </Modal>
                </div>
                <div className="curb-side-footer">
                    {this.props.layoutScreen && !this.props.layoutScreen.hideSocialMedia &&
                        <SocialMedia
                            curbsidePickup={true}
                            layoutScreen={this.props.layoutScreen}
                        />
                    }
                </div>
            </div>
        )
    }
}
export default CurbSideMobileView;
