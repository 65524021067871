const polishStrings = {
    YourShipmentHasBeenDelivered: 'Twoja przesyłka została doręczona', 
    DeliveredBy: 'Osoba doręczająca', 
    RateYourExperience: 'Oceń usługę', 
    ThankYouForYourValuableFeedback: 'Dziękujemy za cenne uwagi', 
    YourFeedbackWillContinuallyHelpUsImproveOurServices: 'Pomogą nam one w ciągłym udoskonalaniu naszych usług', 
    DeliveryFailed: 'Doręczenie nie powiodło się', 
    AddDeliveryPreferences: 'Dodaj preferencje doręczenia', 
    YourDeliveryPreferenceAreSavedSuccessFully: 'Zapisano Twoją preferencję doręczenia', 
    WeAreUnableToTrackYourShipmentRightNow: 'W tej chwili nie jesteśmy w stanie śledzić Twojej przesyłki', 
    PleaseUpdateLocationInsideCircle: 'Zaktualizuj lokalizację w okręgu', 
    Ok: 'Ok', 
    Update: 'Aktualizuj', 
    PickCurrentLocation: 'Wybierz bieżącą lokalizację', 
    SearchFor: 'Wyszukaj', 
    ThisSchedulingLinkHasExpired: 'To łącze do kalendarza utraciło ważność', 
    WeWillShareANewLinkWithYouShortly: 'Wkrótce udostępnimy Ci nowe', 
    UhHo: 'Przepraszamy!', 
    NeedHelp: 'POTRZEBUJESZ POMOCY?', 
    CallSupport: 'Wsparcie przez telefon', 
    EmailSupport: 'Wsparcie za pośrednictwem poczty elektronicznej', 
    DeliveryAt: 'Doręczenie na adres', 
    ContactNo: 'Nr kontaktowy', 
    TrackOnMap: 'Śledź na mapie', 
    MessageToExecutive: 'Wiadomość do członka kadry kierowniczej', 
    Cancel: 'Anuluj', 
    Send: 'Wyślij', 
    Executive: 'Członek kadry kierowniczej', 
    Comments: 'Komentarze (opcjonalnie)', 
    SubmitFeedback: 'PRZEŚLIJ UWAGI', 
    TrackingNo: 'Numer śledzenia', 
    Details: 'Szczegóły', 
    Characters: 'Znaki', 
    Updating: 'aktualizacja...', 
    OopsSomethingWentWrongPleaseTryAfterSomeTime: 'Przepraszamy! Wystąpił błąd. Spróbuj ponownie za jakiś czas', 
    NotListedAboveSendACustomMessage: 'Brak wyszczególnienia powyżej, wyślij wiadomość niestandardową', 
    ChangeDeliveryLocation: 'Zmień lokalizacją doręczenia', 
    AttemptedBy: 'Próba podjęta przez', 
    DeliveredTo: 'Doręczono do', 
    SendMessage: 'WYŚLIJ WIADOMOŚĆ', 
    VIEW_DETAILS: 'Wyświetl szczegóły', 
    SAVE_LOCATION: 'ZAPISZ LOKALIZACJĘ', 
    LOCATION: 'lokalizacja', 
    SEARCH_ADDRESS: 'Wyszukaj adres', 
    OpenHours: 'Godziny otwarcia', 
    Note: 'Uwaga', 
    Address: 'Adres', 
    Nearest: 'Najbliżej', 
    ContactNumber: 'Numer kontaktowy', 
    FAQS: 'Często zadawane pytania', 
    HowManyTimesCanIChangeMyPickUpPoint: 'Ile razy mogę zmienić punkt odbioru?', 
    YouCanChangeItOnly: 'Możesz go zmienić tylko', 
    GoBack: 'Wróć', 
    SetAsPickupStore: 'Ustaw jako punkt odbioru', 
    MapView: 'Widok mapy', 
    SearchForPickupPointNearYou: 'Wyszukaj pobliski punkt odbioru', 
    Arriving: 'W drodze', 
    LiveTrackingWillStart: 'Rozpocznie się śledzenie na żywo', 
    DriverTrackerWillGetActivatedOnceTheyAreOnWay: 'Urządzenie śledzenia zainstalowane u kierowcy włączy się, kiedy przesyłka będzie w drodze', 
    TrackerTimeline: 'Harmonogram śledzenia', 
    PM: 'Popołudniu', 
    AM: 'Rano', 
    TodayAt: 'dziś o', 
    TomorrowAt: 'jutro o', 
    Today: 'Dziś', 
    Tomorrow: 'Jutro', 
    On: 'w dniu', 
    In: 'za', 
    Soon: 'wkrótce', 
    Min: 'min', 
    Mins: 'minut', 
    Items: 'ARTYKUŁY', 
    ViewDetails: 'Wyświetl szczegóły', 
    DeliveryInformation: 'Informacje o doręczeniu', 
    TrackingHistory: 'Historia śledzenia', 
    StayConnected: 'Pozostań w kontakcie', 
    YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'W ciągu kilku ostatnich minut próbowałeś(-aś) już dokonać płatności. Czy chcesz anulować poprzednie transakcje?', 
    No: 'NIE', 
    yes: 'TAK', 
    YourPackageWillBeDeliveredBy: 'Twoja paczka zostanie doręczona do', 
    OrderDetails: 'Szczegóły zamówienia', 
    OrderInformation: 'Informacje dotyczące zamówienia', 
    Thankyou: 'Dziękujemy', 
    LastAttempted: 'Ostatnia próba', 
    DeliveryBy: 'DoręczenieDo', 
    MsgToFePageHeader: 'Prześlij instrukcje dotyczące doręczenia', 
    MsgToFeSupportingText: 'Co chcesz przekazać kurierowi?', 
    AddYourMsgHere: 'Dodaj tu swoją wiadomość', 
    InstructionsSentSuccessfully: 'Wysłano instrukcje', 
    SomethingWentWrongPlsTryAgain: 'Wystąpił błąd. Spróbuj ponownie później.', 
    times: 'razy', 
    TrackMovementInRealTime: 'Śledź ruch w czasie rzeczywistym', 
    SetAsPickupStore: 'Ustaw jako punkt odbioru', 
    MapView: 'Widok mapy', 
    DeliveredAt: 'Doręczono o', 
    LastAttempted: 'Ostatnia próba', 
    Tomorrow: 'Jutro',
    WriteYourFeedbackOptional:"Napisz swoją opinię (opcjonalnie)",
    ReturnOrder: "Return Order" ,
    TheLinkYouAreTryingToOpenHasExpired: "Link, który próbujesz otworzyć, wygasł    ",
    SelectAnAvailableTimeSlot:"Select an available time slot",
    January:"JANUARY",
    February:"FEBRUARY",
    March :"MARCH",
    April:"APRIL",
    May:"MAY",
    June:"JUNE",
    July:"JULY",
    August:"AUGUST",
    September:"SEPTEMBER",
    October:"OCTOBER",
    November:"NOVEMBER",
    December:"DECEMBER",
    Monday:"MONDAY",
    Tuesday:"TUESDAY",
    Wednesday:"WEDNESDAY",
    Thursday:"THURSDAY",
    Friday:"FRIDAY",
    Saturday:"SATURDAY",
    Sunday:"SUNDAY",
    November:"NOVEMBER",
    ViewTimings: "View Timings",
    HideTimings: "Hide Timings",
    PleaseAnswerAllMandatoryQuestions:"Proszę odpowiedzieć na wszystkie obowiązkowe pytania",
    HelpLineLink: 'Helpline Link',
    ShipmentOf: "Wysyłka%z",
    OtherShipments: "Inne Przesyłki",
    OrderInformation:"Order Information",
    OR:"lub",
    TrackShipment:"Śledź przesyłkę",
    TrackingDescription:"Wpisz poniższe szczegóły, aby uzyskać szczegółowe informacje na temat przesyłki",
    Continue:"Kontynuować",
    InvalidCredentials:"Nieprawidłowe dane logowania. Proszę spróbować ponownie",
    ITEM: 'element',
    ITEMS: 'element(y)',
    OrderNo:"Numer zamówienia",
    CurrentStatus:"Obecny status",
PickupTime:"Czas odbioru",
StoreLocation:"Store Location",
LeaveBy:"Leave By",
toReachByScheduledTime:"to reach by scheduled time",
ShareThisLink:"Share this Link",
ETAtime:"ETA time",
ParkingInstructions:"Parking instructions",
PR1_heading:"Find a Spot",
PR2_heading:"Park Safely",
PR3_heading:"Secure and Exit",
PR1_description:"Look for a marked parking space; ensure it's not restricted or reserved.",
PR2_description:" Align your vehicle within the lines and leave enough space for others.",
PR3_description:"Turn off the engine, engage the handbrake, lock the car, and take your belongings.",
willBringYourOrderShortly:"will bring your order shortly.",
PODImage:"POD Image",
CopiedtoClipboard:"Copied to Clipboard",
}
export default polishStrings;