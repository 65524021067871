import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button"; 
import Header from "../../components/Header";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import { isEmpty } from "loadsh";
import Checkbox from "@material-ui/core/Checkbox";
import FeedbackV3 from "../FeedbackV3/index";
import "./style.css";
import { Chip, Drawer } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import TextArea from "antd/lib/input/TextArea";
import {StarOutline, StarFilled} from "../FeedbackV3/Icons";
import moment from 'moment';
import * as CurbsidePickupActionCreator from "../CurbsidePickupContainer/redux/curbsidePickupActionCreator";

const ColorButton = withStyles((theme) => ({
    root: {
      color: "white",
      width: "100%",
      height: 45,
      backgroundColor: "#18BF9A",
      "&:hover": {
        backgroundColor: "#18BF9A",
      },
    },
  }))(Button);

  class FeedbackScreenV3 extends React.Component {
    containerConstants = window.containerConstants;
  
    ratingMap = ["One", "Two", "Three", "Four", "Five"];
  
    constructor(props) {
      super(props);
      this.state = {
        ratingValue: "",
        checkBoxValue: [],
        comment: "",
        isFeedback:false
      };
     this.fontType = this.props.trackingDetails.newLayout?this.props.trackingDetails.customerInteractionThemeSettingsDTO?.newCiData?.defaultSettings?.typeface?.fontType:"Roboto";
    }
  
    componentDidMount(){
        if(this.props.feedbackInfo && !isEmpty(this.props.feedbackInfo) && this.props.ratings==this.props.feedbackInfo.ratingValue){
            if(!isEmpty(this.props.feedbackInfo.comment) && !isEmpty(this.props.feedbackInfo.checkBoxValue)){
                this.setState({comment:this.props.feedbackInfo.comment,checkBoxValue:this.props.feedbackInfo.checkBoxValue});
            } else if(!isEmpty(this.props.feedbackInfo.comment)){
              this.setState({comment:this.props.feedbackInfo.comment});
            } else if(!isEmpty(this.props.feedbackInfo.checkBoxValue)){
              this.setState({checkBoxValue:this.props.feedbackInfo.checkBoxValue});
            }
        }
    }
    
    setChildRating = (event) => {
      this.setState({ ratingValue: event.target.value,checkBoxValue:[],comment:""});
    };
  
    getRatingValue = (value) => {
      let checked = value === this.state.ratingValue ? true : false;
      return checked;
    };


    isChipActive = (value) => {
        const reason = this.state.checkBoxValue;
        for (let i = 0; i < this.state.checkBoxValue.length; i++) {
          if (this.state.checkBoxValue[i] == value) 
          return false; }
          return true;
      }

      updateCheckedValues = (checked, value) => {
        const reason = this.state.checkBoxValue;
        if (checked) {
          reason.push(value);
          this.setState({ checkBoxValue: reason });
        } else {
          let index = -1;
          for (let i = 0; i < this.state.checkBoxValue.length; i++) {
            if (this.state.checkBoxValue[i] == value) {
              index = i;
              break;
            }
          }
          if (index != -1) {
            reason.splice(index, 1);
            this.setState({ checkBoxValue: reason });
          }
        }
      };
    
      getReasons = () => {
        const feedback = this.props.trackingDetails.feedback
          ? JSON.parse(this.props.trackingDetails.feedback)
          : "";
        let values = [];
        let ratingHeadingV3 ="";
        let ratingTemp = parseInt(this.state.ratingValue);
        if (feedback.captureFeedbackV3 && feedback.captureFeedbackV3 !="allowed"){
            if (feedback && this.state.ratingValue && feedback.reason) {
              while (ratingTemp < 6) {
                let found = false;
                for (let i = 0; i < feedback.reason.length; i++) {
                  if (
                    feedback.reason[i] &&
                    feedback.reason[i].rating &&
                    feedback.reason[i].values &&
                    feedback.reason[i].rating == this.ratingMap[ratingTemp - 1]
                  ) {
                    values = feedback.reason[i].values;
                    found = true;
                    break;
                  }
                }
                ratingTemp = found ? 6 : ratingTemp + 1;
              }
            }
          }else{
            if (feedback && this.state.ratingValue && feedback.reasonv3) {
              while (ratingTemp < 6) {
                let found = false;
                for (let i = 0; i < feedback.reasonv3.length; i++) {
                  if (
                    feedback.reasonv3[i] &&
                    feedback.reasonv3[i].rating &&
                    (feedback.reasonv3[i].values || feedback.reasonv3[i].ratingHeading) &&
                    feedback.reasonv3[i].rating == ratingTemp
                  ) {
                    values = feedback.reasonv3[i].values ?feedback.reasonv3[i].values:"";
                    ratingHeadingV3 = feedback.reasonv3[i].ratingHeading
                    found = true;
                    break;
                  }
                }
                ratingTemp = found ? 6 : ratingTemp + 1;
              }
            }
        }
    
        if (!isEmpty(values)|| !isEmpty(ratingHeadingV3) ) {
          return (
            <div>
            <div className="feedback-chips-title"
                 style={{
                  color:this.props.newCiData?.deliverRating?.description?.fontColour,
                  fontWeight:this.props.newCiData?.deliverRating?.description?.fontWeight,
                  fontFamily : this.props.newCiData?.defaultSettings?.typeface?.fontType
                }}
            >{ feedback.captureFeedbackV3=="allowed" ? ratingHeadingV3 :"Sorry to hear, tell us what went wrong?"}</div>
            <div className="cust-feedback"
              style={{
                color:this.props.newCiData?.deliverRating?.labels?.fontColour,
                fontWeight:this.props.newCiData?.deliverRating?.labels?.fontWeight,
                borderColor:this.props.newCiData?.deliverRating?.labels?.borderColour,
              }}
            >
              {values.map(
                function (box, index) {
                  return (
                    <div className="reason-div" key={index}>
                      <div className={this.getRatingClass()}>
                        <span className="fl-left" style={{
                        }}>
                          <Chip
                          size="medium"
                          style={{fontFamily:`${this.fontType||"Roboto"},sans-serif`}}
                          label={box}
                          onClick={()=>{
                            this.updateCheckedValues(this.isChipActive(box), box);
      
                          }}
                          color={this.isChipActive(box)?"":"primary"}
                          >
                          </Chip>
                        </span>
                      </div>
                    </div>
                  );
                }.bind(this)
              )}
            </div>
            </div>
          );
        }
      };
      getRatingClass = ()=>{
        if(this.state.ratingValue){
           if(this.state.ratingValue == 1){
            return  "star-rating-1"
           }
           else
           if(this.state.ratingValue == 2){
            return  "star-rating-2"
           }
           else
           if(this.state.ratingValue == 3){
            return  "star-rating-3"
           }
           else
           if(this.state.ratingValue == 4){
            return  "star-rating-4"
           }
           else
           if(this.state.ratingValue == 5){
            return  "star-rating-5"
           }
        } 
           return "star-rating-5"; 
      }

      getRatingDiv = () => {
        return (
          <div className="feedbackScreen feedback-rating-selector">
            <div className={this.getRatingClass()}>
              <input
                type="radio"
                id="1-star"
                name="rating"
                value="5"
                checked={this.getRatingValue("5")}
                onClick={this.setChildRating}
              />
              <label htmlFor="1-star" className="star">
                <StarOutline/>
                <StarFilled/>
              </label>
              <input
                type="radio"
                id="2-stars"
                name="rating"
                value="4"
                checked={this.getRatingValue("4")}
                onClick={this.setChildRating}
              />
              <label htmlFor="2-stars" className="star">
                <StarOutline/>
                <StarFilled/>
              </label>
              <input
                type="radio"
                id="3-stars"
                name="rating"
                value="3"
                checked={this.getRatingValue("3")}
                onClick={this.setChildRating}
              />
              <label htmlFor="3-stars" className="star">
                <StarOutline/>
                <StarFilled/>
              </label>
              <input
                type="radio"
                id="4-stars"
                name="rating"
                value="2"
                checked={this.getRatingValue("2")}
                onClick={this.setChildRating}
              />
              <label htmlFor="4-stars" className="star">
                <StarOutline/>
                <StarFilled/>
              </label>
              <input
                type="radio"
                id="5-stars"
                name="rating"
                value="1"
                checked={this.getRatingValue("1")}
                onClick={this.setChildRating}
              />
              <label htmlFor="5-stars" className="star">
                <StarOutline/>
                <StarFilled/>
              </label>
            </div>
          </div>
        );
      };
    
      getCommentDiv = () => {
        return (
          <div className="comments-optional">
            
             <TextField
                  //id="standard-name"
                  id="standard-multiline-flexible"
                  // variant="outlined"
                  label={containerConstants.formatString(containerConstants.WriteYourFeedbackOptional)}
                  // margin="normal"
                  className="width-100"
                  multiline
                  maxRows={4}
                  value={this.state.comment}
                  onChange={(event) => {
                    this.setState({ comment: event.target.value });
                  }}
                  InputLabelProps={{
                      style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`,},
                  }}
                  InputProps={{
                      style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                  }}

              />
          </div>
        );
      };

      
      saveFeedback = async(event) => {
        const feedback = JSON.parse(this.props.trackingDetails.feedback);
        let themeSetting = null;
        if (
          !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO)
        ) {
          themeSetting = JSON.stringify(
            this.props.trackingDetails.customerInteractionThemeSettingsDTO
          );
        }
        var feedbackObj = {
          ratingValue: this.state.ratingValue,
          checkBoxValue: this.state.checkBoxValue,
          comment: this.state.comment,
          code: feedback.processMasterCode,
          ratingMappingKey: feedback.ratingMappingKey,
          tagMappingKey: feedback.tagMappingKey,
          commentMappingKey : feedback.commentMappingKey,
          referenceNumber: feedback.referenceNumber,
          logo: this.props.trackingDetails.companyLogo,
          customerInteractionThemeSettingsDTO: themeSetting,
          orderId:this.props.trackingDetails.orderId,
          feedback_config: this.props.trackingDetails.awb
        };

       if(!isEmpty(feedback.feedbackSubmissionTimestampKey)) {
          feedbackObj['feedbackSubmissionTimestampKey'] = feedback.feedbackSubmissionTimestampKey;
          feedbackObj['feedbackSubmissionTimestamp'] = moment().format('YYYY-MM-DD HH:mm:ss');
       }

       this.setState({'isFeedback':!this.state.isFeedback});
       if(this.props.shipmentTracking){
        let res = await this.props.actions.saveFeedbackShipment(this.props.url, feedbackObj,this.props.trackingDetails, false, this.props.type);
       }
       else{
        let res = await this.props.actions.saveFeedback(this.props.url, feedbackObj,this.props.trackingDetails, false, this.props.type,this.props.isReturn);
       }
       if(this.props.type == "CURBSIDE_PICKUP"){
        let res1= await this.props.actions.setCurbsidePickupData(this.props.url);
       }
       else{
       let res1 = await this.props.actions.getTrackingDetails(this.props.url,this.props.isReturn,this.props.trackingDetails);
       }
       this.setState({'isFeedback':!this.state.isFeedback})
       this.props.backScreen(this.state.ratingValue);
      };

      getFeedbackRatingBox=(feedback)=>{
        let ctaFontColor= this.props?.trackingDetails?.customerInteractionThemeSettingsDTO?.ctaFontColor;
        return (<React.Fragment>
        <div className="feeback-screen-content"
          style={{
            backgroundColor:this.props.newCiData?.deliverRating?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cardBgcolour:this.props.newCiData?.deliverRating?.card?.cardBgcolour,
            borderRadius:`${this.props.newCiData?.deliverRating?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cornerRedius:this.props.newCiData?.deliverRating?.card?.cornerRedius||0}px`,
            fontFamily : this.props.newCiData?.defaultSettings?.typeface?.fontType
          }}
        >
                <div className="feeback-modal-slide-header">
                  <h2 className="m0 bold fs18"
                     style={{
                      color:this.props.newCiData?.deliverRating?.heading?.fontColour,
                      fontWeight:this.props.newCiData?.deliverRating?.heading?.fontWeight,
                      fontFamily : this.props.newCiData?.defaultSettings?.typeface?.fontType

                    }}>
                      {feedback && feedback.feedbackHeading && !isEmpty(feedback.feedbackHeading)?feedback.feedbackHeading:
                        containerConstants.formatString(containerConstants.RateYourExperience)}
                  </h2>
                  <i class="bi bi-x-lg feeback-modal-slide-close" 
                     onClick={()=> this.props.backScreen()}
                  > 
                  </i>
                </div>
                <div className="feeback-modal-slide-container">
                    {this.getRatingDiv()}
          
                    {this.getReasons()}
          
                    {this.getCommentDiv()}
                  </div>
              </div>  
            <div className="feeback-modal-slide-button">
              <ColorButton
                disabled = {this.state.isFeedback}
                id = "submitFeedback"
                variant="contained"
                color="primary"
                onClick={this.saveFeedback}
                style={{
                  background: this.props.primaryBgColor
                    ? this.props.primaryBgColor
                    : this.props.trackingDetails.buttonBackGroundColor,
                  color: this.props.primaryTextColor
                    ? this.state.isFeedback ?'':this.props.primaryTextColor
                    : this.props.trackingDetails.buttonFontColor,

                }}
              >
                <div id = "submitFeedback" style={{fontFamily:`${this.fontType||"Roboto"},sans-serif`, color:ctaFontColor}}>
                {containerConstants.formatString(containerConstants.SubmitFeedback)}

                </div>
              </ColorButton>
            </div>
            </React.Fragment>);
      }

      render() {
        const feedback = JSON.parse(this.props.trackingDetails.feedback);
        if (this.props.feedback) {
          return (
            <FeedbackV3
              primaryBgColor={this.props.primaryBgColor}
              primaryTextColor={this.props.primaryTextColor}
              fontFamily={this.props.fontFamily ? this.props.fontFamily : this.props.newCiData?.defaultSettings?.typeface?.fontType}
              fontSize={this.props.fontSize}
              secondaryBgColor={this.props.secondaryBgColor}
              secondaryTextColor={this.props.secondaryTextColor}
            />
          );
        }
        if (!this.state.ratingValue || isEmpty(this.state.ratingValue)) {
          this.setState({ ratingValue: this.props.ratings });
        }
        if(this.props.isMobile){
          return(
        <Drawer
        anchor="bottom"
        open={this.props.showFeebackOptions}
        onClose={()=> this.props.backScreen()}
        >{
          this.getFeedbackRatingBox(feedback)
          //  <React.Fragment>
    
          //   <div className="feeback-screen-content">
          //       <div className="feeback-modal-slide-header">
          //         <h2 className="m0 bold fs18">
          //             {feedback && feedback.feedbackHeading && !isEmpty(feedback.feedbackHeading)?feedback.feedbackHeading:
          //               containerConstants.formatString(containerConstants.RateYourExperience)}
          //         </h2>
          //         <i class="bi bi-x-lg feeback-modal-slide-close" 
          //         onClick={this.saveFeedback}
          //         > 
          //         </i>
          //       </div>
          //       <div className="feeback-modal-slide-container">
          //           {this.getRatingDiv()}
          
          //           {this.getReasons()}
          
          //           {this.getCommentDiv()}
          //         </div>
          //     </div>  
          //   <div className="feeback-modal-slide-button">
          //     <ColorButton
          //       disabled = {this.state.isFeedback}
          //       variant="contained"
          //       color="primary"
          //       onClick={this.saveFeedback}
          //       style={{
          //         background: this.props.primaryBgColor
          //           ? this.props.primaryBgColor
          //           : this.props.trackingDetails.buttonBackGroundColor,
          //         color: this.props.primaryTextColor
          //           ? this.state.isFeedback ?'':this.props.primaryTextColor
          //           : this.props.trackingDetails.buttonFontColor,
          //       }}
          //     >
          //       {containerConstants.formatString(containerConstants.SubmitFeedback)}
          //     </ColorButton>
          //   </div>
          //   </React.Fragment>
            
      }</Drawer>);
      }else{
        return this.getFeedbackRatingBox(feedback);
      }
    }
}

function mapStateToProps(state) {
    return {
      trackingDetails: state.customerInteractionReducer.trackingDetails,
      url: state.customerInteractionReducer.url,
      feedback: state.customerInteractionReducer.feedback,
      shipmentTracking: state.customerInteractionReducer.shipmentTracking,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ ...CustomerInteractionAction,...CurbsidePickupActionCreator }, dispatch),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(FeedbackScreenV3);
