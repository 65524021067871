
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };
  return `${day}${getOrdinalSuffix(day)} ${month}`;
};
const formatTime = (date) => {
  return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
};
export const formatTimeRange = (time, min, max) => {
  const date = new Date(time);
  if (typeof (min) == 'number' && typeof (max) == 'number') {
    const startTime = min !== null ? new Date(date.getTime() - min * 60 * 1000) : date;
    const endTime = max !== null ? new Date(date.getTime() + max * 60 * 1000) : date;
    return `${formatTime(startTime)} - ${formatTime(endTime)}`;
  }
  // else {
  //   const hours = date.getHours().toString().padStart(2, '0'); // Ensures two digits
  //   const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensures two digits
  //   return `${hours}:${minutes}`;
  // }
  return formatTime(date);
}
export const formateText = (text) => {
  const regex = /<b>(.*?)<\/b>|<i>(.*?)<\/i>/g;
  const parts = [];
  let lastIndex = 0;
  let match;
  while ((match = regex.exec(text)) !== null) {
    parts.push(text.slice(lastIndex, match.index));
    if (match[1]) {
      parts.push(<b key={match.index}>{match[1]}</b>); // Bold text
    } else if (match[2]) {
      parts.push(<i key={match.index}>{match[2]}</i>); // Italic text
    }
    lastIndex = regex.lastIndex;
  }
  parts.push(text.slice(lastIndex));
  return parts;
};
