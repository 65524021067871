
import React from 'react';
import {isEmpty} from 'loadsh'
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { Call, ChatIcon, Edit, Email, Pudo } from './HelpSection/icons';
import { add } from 'lodash';
import { getBorderValue } from '../Utils/themeConfig';



class NewHelpSection extends React.Component {

    constructor(props) {
        super(props);
    }

    getProcessFormList =(fontSize,fontFamily,careAction, helpLineLinkLabel, helpLineLink,showSupportDetail)=>{
        if(!isEmpty(this.props.trackingDetails) && this.props.trackingDetails.processUpdateForm){
         let forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
         let arrayList  = [];
         if(helpLineLink && showSupportDetail){
            arrayList.push(<ListItem button  key ={-1}>
            <a href={helpLineLink} target='_blank' 
                style={{
                fontFamily: `${this.props.newCiData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`,
                fontSize:fontSize ? fontSize : '14px',
                textDecoration:'underline',
                fontWeight:'400',
                color:careAction?.pufLinks?.fontColour,
                }} 
                className='text-underline fs14'
            >   
                {!isEmpty(helpLineLinkLabel)?helpLineLinkLabel:containerConstants.formatString(containerConstants.HelpLineLink)}
            </a>
        </ListItem>)
         }
         if(forms && forms.formList && !isEmpty(forms.formList)){
            (forms.formList).map((form,index)=>{
              if(form.introText)
              arrayList.push(
                <ListItem button  id= "processUpdateForm" key ={index} onClick={this.props.openNeedHelpProcessForm(index)} style={{color:careAction?.pufLinks?.fontColour}}>
                    <span 
                      style={{
                        fontFamily: `${this.props.newCiData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`,
                        fontSize:fontSize ? fontSize : '14px'}} 
                        className='text-underline fs14'>   
                        {form.introText}
                    </span>
                </ListItem>
                )
             })
         }
         return arrayList;
    }
}
    getDrawerNeedHelpText = (callSupport,emailSupport) => {
        let additionalText=null;
        if(callSupport && emailSupport)
            additionalText= callSupport + ` ${containerConstants.formatString(containerConstants.OR)} ` + emailSupport;
        else if(callSupport)
            additionalText= callSupport;
        else if(emailSupport)
            additionalText= emailSupport;
        else 
            additionalText="";
        if (this.props.trackingDetails.drawerNeedHelp && !isEmpty(this.props.trackingDetails.drawerNeedHelp)) {
        
            return this.props.trackingDetails.drawerNeedHelp +" "+ additionalText
        }
        return containerConstants.formatString(containerConstants.NeedHelp)+ " "+ additionalText;
    }
    
    getSection =()=>{
        let theme =
      this.props.trackingDetails &&
      this.props.trackingDetails.customerInteractionThemeSettingsDTO
        ? this.props.trackingDetails.customerInteractionThemeSettingsDTO
        : undefined;
        const fontFamily=theme.fontFamily;
        const fontSize= theme.fontSize;
        let careAction =this.props.newCiData?.careAction;
         let callSupport,emailSupport, helpLineLinkLabel, helpLineLink,showSupportDetail;
         if(this.props.trackingDetails && this.props.trackingDetails.help && !isEmpty(this.props.trackingDetails.help)){
            let help = JSON.parse(this.props.trackingDetails.help);
            showSupportDetail = help?.showSupportDetail == undefined || help?.showSupportDetail ;
            if(help.helpLineContact && !isEmpty(help.helpLineContact) && help.helpLineContact.length > 2){
                callSupport = help.helpLineContact;
            }
            if(help.helpLineEmail && !isEmpty(help.helpLineEmail) && help.helpLineEmail.length > 2){
                emailSupport =  help.helpLineEmail;
             }
             if(help.helpLineLinkLabel && !isEmpty(help.helpLineLinkLabel)){
                helpLineLinkLabel =  help.helpLineLinkLabel;
             }
             if(help.helpLineLink && !isEmpty(help.helpLineLink)){
                helpLineLink =  help.helpLineLink;
             }
          }

        let card = this.props.newCiData?.careAction?.card?.default ? this.props.newCiData?.defaultSettings?.card:this.props.newCiData?.careAction?.card;
        let border = getBorderValue(card?.borderColour||'');
        let formList = this.getProcessFormList(fontSize,fontFamily,careAction,helpLineLinkLabel,helpLineLink,showSupportDetail);
        if(showSupportDetail || !isEmpty(formList)){

            return(
                <div className="help_screen bg-white" style={{padding: "20px 16px",
                    backgroundColor:card?.cardBgcolour,
                    borderRadius:`${card?.cornerRedius||0}px`,
                    borderTop:border[0],borderRight:border[1],borderBottom:border[2],borderLeft:border[3],
                }}>
            <div 
            style={{
                fontWeight:careAction?.heading?.fontWeight, 
                color:careAction?.heading?.fontColour,
                fontFamily: `${this.props.newCiData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`,
            }}
            >
               {this.props.trackingDetails?.needHelp || careAction?.heading?.label || "Questions About Your Order" }
             </div>
             {
                 showSupportDetail  &&
              <p className="mt10" style={{
                  fontFamily: `${this.props.newCiData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`,
                  fontWeight:careAction?.description?.fontWeight, color: careAction?.description?.fontColour
                }}>
              {this.getDrawerNeedHelpText(callSupport,emailSupport) ||  careAction?.description?.label}
              </p>
            }
              <List>
                  {!this.props.isSurveyFormNeedHelp && formList}
              </List>
          </div>
      )
    }
    }

    render(){
        return(
                <div>
                {this.getSection()}
            </div>
        )
    }
}


export default NewHelpSection;