import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Drawer from '@material-ui/core/Drawer';
import Calendar from 'react-calendar';
import DateTimePicker from 'react-datetime-picker';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Radio, Space,Rate, Upload, message } from 'antd';
import { isEmpty, cloneDeep,isEqual} from 'lodash'
import {RightArrow, LikeIcon, DisLikeIcon} from "../../containers/ProcessUpdateForm/Icons";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import * as CustomerInteractionAction from '../../CustomerInteractionActions';
import { TextareaAutosize } from '@material-ui/core';
import { convertFromStandardFormat } from '../../Utils/dateTimeUtil';
import { UploadOutlined } from '@ant-design/icons';
const boxStyle = {
    width: '100%',
    padding: '10px',
    fontFamily: 'Arial, sans-serif',
  };

  const titleStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
    color: '#333',
  };

  const slotListStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '8px',
    padding: '0',
    margin: '0',
  };

  const slotItemStyle = (isSelected) => ({
    padding: '12px',
    backgroundColor: isSelected ? '#c5e1f7' : '#f7f7f7', // Highlight selected slot
    border: '1px solid #ddd',
    borderRadius: '5px',
    textAlign: 'center',
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  });

class SurveyMonkeyFormAttributes extends React.Component {
    
    deliveryDate;
    minDate;
    maxDate;
    disabledDates=[];
    isUpdateImageFile=true;
    dayMap = 
    { 0:containerConstants.formatString(containerConstants.Sunday),
     1: containerConstants.formatString(containerConstants.Monday), 
     2: containerConstants.formatString(containerConstants.Tuesday), 
     3: containerConstants.formatString(containerConstants.Wednesday),
     4: containerConstants.formatString(containerConstants.Thursday), 
     5: containerConstants.formatString(containerConstants.Friday), 
     6: containerConstants.formatString(containerConstants.Saturday) };
    monthMap = 
    {0:containerConstants.formatString(containerConstants.January),
     1: containerConstants.formatString(containerConstants.February), 
     2: containerConstants.formatString(containerConstants.March), 
     3: containerConstants.formatString(containerConstants.April), 
     4: containerConstants.formatString(containerConstants.May), 
     5: containerConstants.formatString(containerConstants.June), 
     6: containerConstants.formatString(containerConstants.July), 
     7: containerConstants.formatString(containerConstants.August), 
     8: containerConstants.formatString(containerConstants.September), 
     9: containerConstants.formatString(containerConstants.October), 
     10:containerConstants.formatString(containerConstants.November), 
     11: containerConstants.formatString(containerConstants.December) }
    selectedSlotDate;
    selectedSlot;
    invalidChars = ["-", "e"];
    
    constructor(props) {
        super(props);
        this.state = {
            calendar: false,
            dateTimeCalendar: false,
            leftIndex: -1,
            rightIndex: -1,
            selectedSlotDate: null,
            selectedSlot: {},
            locationValue: '',
            initialLocationValueEmpty: false,
            isSlotDrawerOpen: false,
            holiday:{count:0,dates:{}},
            autoSaveFlag: 0,
            imageFile:[],
            
        }
        this.calendarType = 'US';
        this.herePoweredAccount =  window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false;
        this.fontType = this.props.trackingDetails.newLayout?this.props.trackingDetails.customerInteractionThemeSettingsDTO?.newCiData?.defaultSettings?.typeface?.fontType:"Roboto";
    }

    autoSavePufData=()=>{
        var autoSaveFlag1=this.props.autoSavePufFlag;
        if(autoSaveFlag1==0){
        this.props.actions.incrementAutoSavePufFlagCount(autoSaveFlag1+1);
        setTimeout(this.props.saveOnlyFormData.bind(this), 10000);
        }
    }
      generateTimeSlotsWithBreakAndExpiry(date, startTime, endTime, intervalMinutes, breakStartTime, breakEndTime) {
        const slots = [];
        
        const currentTime = new Date(); 
        const selectedDate = date ? new Date(date): new Date(); 
    
        
        selectedDate.setHours(parseInt(startTime.split(":")[0]), parseInt(startTime.split(":")[1]), 0, 0);
    
        const end = new Date(selectedDate.getTime());
        end.setHours(parseInt(endTime.split(":")[0]), parseInt(endTime.split(":")[1]), 0, 0);
    
        const breakStart = new Date(selectedDate.getTime());
        breakStart.setHours(parseInt(breakStartTime.split(":")[0]), parseInt(breakStartTime.split(":")[1]), 0, 0);
    
        const breakEnd = new Date(selectedDate.getTime());
        breakEnd.setHours(parseInt(breakEndTime.split(":")[0]), parseInt(breakEndTime.split(":")[1]), 0, 0);
    
        const interval = intervalMinutes * 60000; // Convert minutes to milliseconds
        let slotStart = new Date(selectedDate.getTime());
    
        while (slotStart < end) {
           
            if (slotStart >= breakStart && slotStart < breakEnd) {
                slotStart.setTime(slotStart.getTime() + interval); 
                continue;
            }
    
            const slotEnd = new Date(slotStart.getTime());
            slotEnd.setTime(slotStart.getTime() + interval);
    
           
            const slotStartStr = slotStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
            const slotEndStr = slotEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    
            if (slotEnd >= currentTime) {
                slots.push(`${slotStartStr} - ${slotEndStr}`);
            }
            slotStart.setTime(slotStart.getTime() + interval);
        }
    
        return slots;
    }
    beforeUpload = (file) => {
        // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        // if (!isJpgOrPng) {
            //     errMsg = 'You can only upload JPG/PNG file!';
            // }
        var errMsg = '';
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            if (errMsg != '') errMsg = errMsg + '  And  ';
            errMsg = errMsg + 'Image must be smaller than 5MB!';
        }
    
        const isValidFileName = file.name != '' && !(file.name.includes('\\') || file.name.includes(' ') || file.name.includes('/'));
        if (!isValidFileName) {
            if (errMsg != '') errMsg = errMsg + '  And  ';
            errMsg = errMsg + "Image file name should not contain '/' or '\\' or space in it.";
        }
    
        if (errMsg != ''){message.error(errMsg, 2.5);}
        return (isLt2M && isValidFileName);
    };
    onRemoveImage = (key)=>{
        this.setState({imageFile:[]});
        this.props.updateValue(key, " ")
    }
    setFileListValue = (imgFile) => {
        if (typeof imgFile == 'string') {
            if (imgFile.startsWith('https')){
                return [{
                    uid: '-1',
                    status: 'done',
                    name: imgFile.length > 10 ? imgFile.slice(0, 30) + "..." : imgFile,
                    url: imgFile,
                }]
            }
        }
        return [];
    }
    getSimpleTypeAttributes = (attribute) => {
        let { typeId, key, label, required, notEditable, placeHolder, autoSave, value } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
       const slotDate = this.props.formDetailsMap["delivery_eta"]?.value ? new Date(this.props.formDetailsMap["delivery_eta"]?.value): new Date();
       const slots = this.generateTimeSlotsWithBreakAndExpiry(slotDate, "09:00","22:00",30,"13:00","14:00");
       if(attribute.attributeInputType == "imageUpload" && this.isUpdateImageFile){
        this.setState({imageFile:this.setFileListValue(value)});
        this.isUpdateImageFile = false;
       }
        return (
            <div className='monkey-survey-form-group-row'>
            <div className='monkey-survey-form-delivery-date'>
            <div className='monkey-survey-form-delivery-textarea'>
            <label className='monkey-survey-form-label'>{label}{required?<span style={{ color: 'red' }}>*</span>:null}</label>
            <div className='monkey-survey-form-inner-textarea'>
                {/* <textarea className='monkey-survey-input-format' placeholder='Short answer'></textarea> */} 
                    {attribute.key=="unique_demo_slots" ?
                      <div style={boxStyle}>
                      {slots.length === 0 ? (
                        <p>No available slots</p>
                      ) : (
                        <div style={slotListStyle}>
                          {slots.map((slot, index) => (
                            <div
                              key={index}
                              style={slotItemStyle(slot === this.props.formDetailsMap[key]?.value)} 
                              onClick={()=>{
                                this.props.updateValue(key, slot)}}>
                              {slot}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                   :
                    (attribute.attributeInputType == "imageUpload") ? 
                    <Upload
                    accept=".jpg,.jpeg,.png,.heic,.heif,.tiff"
                        fileList={this.state.imageFile}
                        listType="picture"
                        maxCount={1}
                        customRequest={({ onSuccess}) => {
                            setTimeout(() => {
                                onSuccess('ok');
                            }, 0);
                        }}
                        onChange={event => {
                            let imgFile = " ";
                            if (event.fileList && event.fileList.length > 0 && event.fileList[0] && event.fileList[0].status && event.fileList[0].status != "error") {
                                imgFile = event.fileList[0].originFileObj;
                                this.setState({imageFile:event.fileList})
                            }
                            this.props.updateValue(key, imgFile);
                        }}
                        onRemove={()=>this.onRemoveImage(key)}
                        beforeUpload={this.beforeUpload}
                    >                        <Button style={{background:"#ebebeb"}} icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload> :
            <TextareaAutosize
                id="standard-name"
                variant="outlined"
                margin="normal"
                className='monkey-survey-input-format'
                placeholder={placeHolder}
                value={typeId == 52 ? (!isEmpty(this.props.latLngValue) ? this.props.latLngValue : this.state.locationValue)
                    : this.props.formDetailsMap[key].value}
                type={(typeId == 6 || typeId == 27 || typeId == 31) ? "Number" : ""}
                onClick={!notEditable && this.props.checkLatLng(attribute, this.props.index)}
                required={required || !this.props.isAddressSubmitEnabled}
                onChange={event => {
                    if(typeId==27){
                        if(/^([,+]?[#]*\d+)*[#]*$/.test(event.target.value)){
                            this.props.updateValue(key, event.target.value)
                        } 
                    } else {
                        this.props.updateValue(key, event.target.value)
                    }
                    
                }}
                onKeyPress={(event) => {
                    if (this.invalidChars.includes(event.key) && (typeId == 6 || typeId == 27 || typeId == 31)) {
                        event.preventDefault();
                    }
                }}
                onBlur={() => { this.props.runValidation(key, "route");
                                autoSave?this.autoSavePufData():"" }}
                autoComplete='off'
                InputLabelProps={{
                    style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                }}
                InputProps={{
                    style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                }}
                disabled={notEditable}

            />}
            </div>
            </div>
            </div>
            </div>
        );
    }
    checkRadioButtonValue=(code,key)=>{
        const value = this.props.formDetailsMap[key].value;
        if (!isEmpty(value)) {
            if (value == code)
                return true;
            
        }
        return false;
    }
    checkRadioButtonValueNumber=(code,key)=>{
        const value = this.props.formDetailsMap[key].value;
        if (!isEmpty(value)) {
            if (value-'0' >= code-'0')
                return true;
            
        }
        return false;
    }
    getColorCode=(key)=>{
        const value = this.props.formDetailsMap[key].value
        if(!isEmpty(value))
        {if(value-'0'<=6-'0')
        return "#FA6368";
        else if(value-'0'<=8-'0')
        return "#F1C21B";
        else{
        return "#2FCE49";}
        }

    }
    getRadioNumberTypeAttributes = (attribute) => {
        let { typeId, key, label, required, notEditable,processAttributesValues,autoSave } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        
        return (
            <div className='monkey-survey-form-group-row'>
            <div className='monkey-survey-form-delivery-date'>
            <div className='monkey-survey-form-delivery-textarea'>
            <label className='monkey-survey-form-label'>{label}{required?<span style={{ color: 'red' }}>*</span>:null}</label>
            <ul className='monkey-survey-like-counts'>
                {/* <textarea className='monkey-survey-input-format' placeholder='Short answer'></textarea> */}
                          
                
                    {processAttributesValues.map((box) => {
                        return (
                            <li
                                key={box.code}
                                className={this.props.formDetailsMap[key] && this.props.formDetailsMap[key].value?"checked":""}
                                style={{backgroundColor:this.checkRadioButtonValueNumber(box.code,key)?this.getColorCode(key):''}}
                                disabled={notEditable}
                                onClick={()=>{this.props.updateValueSurveyFormWithValidation(key,box.code);
                                    autoSave?this.autoSavePufData():""}}
                            >{box.name}</li>
                        )
                    })
                    }
                
                
            </ul>
            </div>
            </div>
            </div>
        );
    }
    getRadioButtonTypeAttributes = (attribute) => {
        let { typeId, key, label, required, notEditable,processAttributesValues,autoSave } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        
        return (
            <div className='monkey-survey-form-group-row'>
            <div className='monkey-survey-form-delivery-date'>
            <div className='monkey-survey-form-delivery-textarea'>
            <label className='monkey-survey-form-label'>{label}{required?<span style={{ color: 'red' }}>*</span>:null}</label>
            <Radio.Group value={this.props.formDetailsMap[key].value}>
            <Space direction="vertical">
                {/* <textarea className='monkey-survey-input-format' placeholder='Short answer'></textarea> */}
                          
                
                    {processAttributesValues.map((box) => {
                        return (
                            <Radio
                                value={box.code}
                                disabled={notEditable}
                                onClick={()=>{this.props.updateValueSurveyFormWithValidation(key,box.code);
                                        autoSave?this.autoSavePufData():""}}
                            >{box.name}</Radio>
                        )
                    })
                    }

            </Space>        
            </Radio.Group>
            </div>
            </div>
            </div>
        );
    }
    getRadioTypeAttributesThumbs = (attribute) => {
        let { typeId, key, label, required, notEditable,processAttributesValues,autoSave } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        return (
            <div className='monkey-survey-form-group-row'>
            <div className='monkey-survey-form-delivery-date'>
            <div className='monkey-survey-form-delivery-textarea'>
            <label className='monkey-survey-form-label'>{label}{required?<span style={{ color: 'red' }}>*</span>:null}</label>
            <div className='monkey-survey-like-dislike'>
                <button className={!this.checkRadioButtonValue("like",key)?"monkey-survey-like-btn":'monkey-survey-like-btn like-checked'}
                        disabled={notEditable}
                        onClick={()=>{this.props.updateValueSurveyFormWithValidation(key,"like");
                        autoSave?this.autoSavePufData():""}}
                        ><LikeIcon/></button>
                <button className={!this.checkRadioButtonValue("dislike",key)?"monkey-survey-like-btn dlike":"monkey-survey-like-btn dlike-checked"}
                        disabled={notEditable}
                        onClick={()=>{this.props.updateValueSurveyFormWithValidation(key,"dislike");
                        autoSave?this.autoSavePufData():""}}
                        ><DisLikeIcon/></button>
            </div>
            
            </div>
            </div>
            </div>
        );
    }

    calenderDrawer = (calendar, min, max, value) => () => {
        if (min && !isEmpty(min)) {
            this.minDate = min;
        }
        if (max && !isEmpty(max)) {
            this.maxDate = max;
        }
        if (value) {
            this.deliveryDate = value;
        }
        this.setState({
            calendar: calendar
        });
    };
    OpenCalender=(min, max, value)=>{
        if (min && !isEmpty(min)) {
            this.minDate = min;
        }
        if (max && !isEmpty(max)) {
            this.maxDate = max;
        }
        if (value) {
            this.deliveryDate = value;
        }
        this.props.openCalenderNewUi()
    }
    
    getDateTypeAttribute = (attribute) => {
        let { key, label, value, required, minRange, maxRange, notEditable,autoSave } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        const calendars = ['US', 'Arabic', 'Hebrew', 'ISO 8601','US-SUN'];
        if (calendars.indexOf(this.props.calendarType) > -1) {
            this.calendarType = cloneDeep(this.props.calendarType);
        }
        // remove this after prod release...
        if (!isEmpty(value)) {
            let date = new Date(value);
            let currentDate = new Date();
            if (currentDate.getTime() >= date.getTime()) {
                currentDate.setDate(currentDate.getDate() + 1);
                date = currentDate;
                let year = date.getFullYear();
                let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
                let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
                value = year + '-' + month + '-' + day;
                if (!isEmpty(minRange)) {
                    minRange = value;
                }
            }
        }
        return (
            
            <div className='monkey-survey-form-group-row'>
            <div className='monkey-survey-form-delivery-date'>
                <label className='monkey-survey-form-label'>{label}</label>
                <input  className='monkey-survey-input-format' 
                    id="standard-name-calender"
                    variant="outlined"
                    label={label}
                    margin="normal"
                    required={required}
                    value={value ? convertFromStandardFormat(value, this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && this.props.trackingDetails.customerInteractionThemeSettingsDTO.dateFormat) : ''}
                    onClick={()=>{this.OpenCalender(minRange, maxRange, value)}}/>
                {this.props.showCalender?(
                <div
                    style={{maxWidth:"418px", width:"100%"}}    
                ><Calendar
                    locale={this.handleLanguage(this.props.language)}
                    id="calender-new" 
                    tileClassName={this.getTileClassName}
                    tileDisabled={({ date }) => this.checkIfDateDisable(date)}
                    calendarType={this.calendarType.indexOf('US-SUN')!=-1?'US':this.calendarType}
                    onChange={(event) => {
                        let year = event.getFullYear();
                        let month = (event.getMonth() + 1) < 10 ? '0' + (event.getMonth() + 1) : (event.getMonth() + 1);
                        let date = event.getDate() < 10 ? '0' + event.getDate() : event.getDate()
                        let value = year + '-' + month + '-' + date;
                        this.updateDateType(this.props.attribute.key,value);
                        autoSave?this.autoSavePufData():"";
                    }}
                    onClickDay={this.calenderDrawer(false)}
                    maxDate={this.maxDate && !isEmpty(this.maxDate) ? this.getMaxDateForCalendar() : ''}
                    minDate={this.minDate && !isEmpty(this.minDate) ? new Date(this.minDate) : ''}
                    value={!isEmpty(this.deliveryDate) ? new Date(this.deliveryDate) : new Date()}
                /></div>):null}
                
            </div>
            </div>
        )
    }


    updateCheckedValues = (key, checked, code) => {
        let checkedArray = [];
        if (checked) {
            if (isEmpty(this.props.formDetailsMap[key].value)) {
                checkedArray.push(code)
                this.props.updateValue(key, checkedArray)
            } else {
                checkedArray = cloneDeep(this.props.formDetailsMap[key].value);
                checkedArray.push(code);
                this.props.updateValue(key, checkedArray)
            }
        } else if (!isEmpty(this.props.formDetailsMap[key].value)) {
            checkedArray = this.props.formDetailsMap[key].value.filter(val => val !== code);
            this.props.updateValue(key, checkedArray);
        }
    }

    getCheckedValues = (key, code) => {
        const checkedArray = this.props.formDetailsMap[key].value;
        if (!isEmpty(checkedArray)) {
            for (let i = 0; i < checkedArray.length; i++) {
                if (checkedArray[i] == code)
                    return true;
            }
        }
        return false;
    }



    getCheckBoxMenuTypeAttribute = (attribute) => {
        let { key, required, processAttributesValues, notEditable,label,autoSave } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        return (
            <div className='monkey-survey-form-group-row'>
            <div className='monkey-survey-form-delivery-date'>
            <label className='monkey-survey-form-label'>{label}{required?<span style={{ color: 'red' }}>*</span>:null}</label>
                        
                <FormGroup onBlur={() => { this.props.runValidation(key, "route");
                                            autoSave?this.autoSavePufData():"";
                                         }}>
                    {processAttributesValues.map((box) => {
                        return (
                            <FormControlLabel
                                style={{width:"fit-content"}}
                                key={box.code}
                                control={
                                    <Checkbox
                                        style={{padding:'6px'}}
                                        checked={this.getCheckedValues(key, box.code)}
                                        value={box.code}
                                        onChange={event => {
                                            this.updateCheckedValues(key, event.target.checked, box.code);
                                            
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        style={{ color: this.props.fontColor}}>
                                        {box.name}
                                    </Typography>}
                                disabled={notEditable}
                            />
                        )
                    })
                    }

                </FormGroup>
            </div>
            </div>
        )
    }


    getDropDownSelectTypeAttribute = (attribute) => {
        let { key, label, required, processAttributesValues, notEditable ,autoSave} = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        return (
            <div className='monkey-survey-form-group-row'>
            <div className='monkey-survey-form-delivery-date'>
            <div className='monkey-survey-form-delivery-textarea'>
            <label className='monkey-survey-form-label'>{label}{required?<span style={{ color: 'red' }}>*</span>:null}</label>
            <div className='monkey-survey-form-inner-textarea'>
                <TextField
                    select
                    margin="normal"
                    // variant="outlined"
                    className='monkey-survey-input-format'
                    value={this.props.formDetailsMap[key].value}
                    autoComplete='off'
                    required={required}
                    onChange={event => {
                        this.props.updateValue(key, event.target.value)
                    }}
                    onBlur={() => { this.props.runValidation(key, "route") ;
                    autoSave?this.autoSavePufData():"";}}
                    InputLabelProps={{
                        style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize||14 },
                    }}
                    InputProps={{
                        style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize||14 },
                    }}
                    disabled={notEditable}
                >
                    {processAttributesValues.map(option => (
                        <MenuItem key={option.name} value={option.code} style={{ color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize||14 }}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            </div>
            </div>
            </div>
        )
    }

    moveToLeft = () => event => {
        if (this.state.leftIndex - 3 > 1) {
            this.rightIndex = this.leftIndex -1;
            this.leftIndex = this.leftIndex - 3;
            this.setState({ 'leftIndex': this.leftIndex  , 'rightIndex': this.rightIndex})
        }
        else if (this.state.leftIndex > 0) {
            if(this.leftIndex == 4){
                this.leftIndex = 1;
                this.rightIndex = this.leftIndex + 2;
                this.setState({ 'leftIndex': 1, 'rightIndex': 3 })
            }
            else{
            this.leftIndex = 0;
            this.rightIndex = this.leftIndex + 2;
            this.setState({ 'leftIndex': 0, 'rightIndex': 2 })
            }

        }
        else {
            //disabled
        }
    }

    moveToRight = (dynamicSlotDate) => event => {
        if (this.state.rightIndex + 3 < dynamicSlotDate.length) {
            this.leftIndex = this.rightIndex + 1;
            this.rightIndex = this.rightIndex + 3;
            this.setState({ 'leftIndex': this.state.rightIndex + 1, 'rightIndex': this.state.rightIndex + 3 })
        } else if (this.state.rightIndex < dynamicSlotDate.length) {
            this.rightIndex = dynamicSlotDate.length - 1;
            this.leftIndex = this.rightIndex - 2;
            this.setState({ 'leftIndex': dynamicSlotDate.length - 3, 'rightIndex': dynamicSlotDate.length - 1 })

        } else {
            //disabled
        }
    }

    getAvailableSlots = (date,coordinatorSlotObject) => {
        this.setState({ selectedSlotDate: date, selectedSlot: {} });
        this.props.getDynamicSlots(date,coordinatorSlotObject);
    }

    selectSlotValue = slot => {
        this.props.setDynamicSlots(slot);
        this.setState({ 'selectedSlot': slot });
    }

    getDynamicDate = (value, dynamicSlotDate, minRange, maxRange) => {

        let date = [];
        if (!this.state.selectedSlotDate) {
            this.setState({ 'selectedSlotDate': value });
        }
        for (let i = this.state.leftIndex; i <= this.state.rightIndex; i++) {
            if (dynamicSlotDate && dynamicSlotDate[i]) {
                let date_array = dynamicSlotDate[i].split("-");
                let day=date_array[2];
                let dateObject = new Date(date_array[0],date_array[1]-1,date_array[2]);
                let dayName = dateObject.getDay()
                let monthName = dateObject.getMonth();
                let year = dateObject.getFullYear().toString().substr(2);
                
                if (day < 10) {
                    day = day.replace(/^0+/, '');    // if day is less then 10 then  show only single digit
                }
                date.push(
                    <div className={`date-block ${this.state.selectedSlotDate == dynamicSlotDate[i] ? "active" : ''}`} onClick={() => this.getAvailableSlots(dynamicSlotDate[i],this.props.availableDatesFromCoordinator[dynamicSlotDate[i]])}>
                        <p className="day-name">{this.dayMap[dayName].substr(0,3).toUpperCase()}</p>
                        <p style={{ textAlign: 'center' }}>{day}</p>
                        <p className="month-year">{`${this.monthMap[monthName].substr(0,3).toUpperCase()}\`${year}`}</p>
                    </div>
                )
            }
        }
        return date;

    }
    // filter slotes dates according to current date
    filterSlotDatesWithCurrentDate = (dynamicSlotDates)=>{
        const currentDate = new Date();
        const newSlotDates = dynamicSlotDates.filter((slotDate)=>{
            return new Date(slotDate) > currentDate;
        });
        return newSlotDates;
    }

    getDynamicSlotTypeAttribute = (attribute,dynamicSlotDates) => {
        let { key, label, value, required, minRange, maxRange, hidePreviousDates} = attribute;

        //check hidePreviousDates node is enable
        if(hidePreviousDates){
            dynamicSlotDates=this.filterSlotDatesWithCurrentDate(dynamicSlotDates);
        }

        let alertColor = this.props.alertMessage == "No Available Slot Found" ? 'red' : 'green';

        if (this.props.formDetailsMap[key].hide || isEmpty(dynamicSlotDates)) {
            return null;
        }

        dynamicSlotDates.sort(function(a, b) {
            return new Date(a) -  new Date(b);
        });

        if (this.state.leftIndex == -1) {
            
            let indexofValue = dynamicSlotDates.indexOf(value);
            let lengthOfDynamicSlots = dynamicSlotDates.length;
            if(indexofValue > 0){
                if(indexofValue  == lengthOfDynamicSlots -1){
                    this.leftIndex = indexofValue -2;
                    this.rightIndex = indexofValue;
                    this.setState({ 'leftIndex': this.leftIndex, 'rightIndex': this.rightIndex }); 
                }
                else{
                    this.leftIndex = indexofValue -1;
                    this.rightIndex = indexofValue +1;
                    this.setState({ 'leftIndex': this.leftIndex, 'rightIndex': this.rightIndex });
                }
               
            }
            else{
             this.setState({ 'leftIndex': 0, 'rightIndex': 2 })
            }
           
            // let midIndex = Math.floor((dynamicSlotDates.length)/2);
            // if(midIndex>0){
            //     this.setState({ 'leftIndex': midIndex - 1, 'rightIndex': midIndex + 1 })
            // }
            // else {
            //     this.setState({ 'leftIndex': midIndex, 'rightIndex': midIndex + 1 })
            // }
            // if (minRange != 0) {
            //     let min = JSON.parse(minRange);
            //     this.setState({ 'leftIndex': min - 1, 'rightIndex': min + 1 })
            // } else {
            //     this.setState({ 'leftIndex': 0, 'rightIndex': 2 })
            // }
            return;
        }

        return (
            <div>
                <div className="date-block">
                    <div className="slot-date-blocks">
                        {this.getDynamicDate(value, dynamicSlotDates, minRange, maxRange)}
                        <div className="slot-date-nav">
                            <div className="slot-nav-left" onClick={this.moveToLeft()}>
                                <img src={require('../../images/left-arrow.png')} />
                            </div>
                            <div className="slot-nav-right" onClick={this.moveToRight(dynamicSlotDates)}>
                                <img src={require('../../images/right-arrow.png')} />
                            </div>
                        </div>
                    </div>
                </div>
                {(!(!isEmpty(this.props.config3PLDataMap) && this.props.config3PLDataMap[this.props.form.title] && this.props.config3PLDataMap[this.props.form.title].fullDaySlotsEnabled) && !isEmpty(this.props.slotList)) ?
                        <div className="slot-time ml15">
                            <p style={{ marginTop: '5px', marginBottom: '5px' }}>{label}</p>
                            <div className="slot-blocks">
                                {(this.props.slotList).map((slot, index) => {
                                    return <div key={index} 
                                    className={isEqual(this.state.selectedSlot,slot)&&"active"}
                                    onClick={()=>this.selectSlotValue(slot)}
                                    >{slot.start || slot.startTime}-{slot.end || slot.endTime}</div>
                                })}
                            </div>

                        </div> : null
                }
                <p style={{ color: alertColor, marginLeft: '15px', marginTop: '2px' }} className="fs15">{this.props.alertMessage}</p>

            </div>
        )
    }

    getDateTimeTypeAttribute = (attribute) => {
        let { key, label, value, required, hidden, notEditable } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        return (
            <div>
                <TextField
                    id="standard-name"
                    variant="outlined"
                    label={label}
                    margin="normal"
                    className="width-100"
                    required={required}
                    onClick={event => { !notEditable && this.setState({ 'dateTimeCalendar': !this.state.dateTimeCalendar }) }}
                    value={value ? convertFromStandardFormat(value, this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && this.props.trackingDetails.customerInteractionThemeSettingsDTO.dateFormat) : ''}
                    autoComplete='off'
                    InputLabelProps={{
                        style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                    }}
                    InputProps={{
                        style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                    }}
                    disabled={notEditable}
                />
            </div>
        )
    }

    getTileClassName(date, view) {
        let classes = [];
        if (date && date.date.getDay() == 6) {
            classes.push('tileClassName');

        }
        return classes;

    }

    getActiveDate = () => {
        if (this.deliveryDate && !isEmpty(this.deliveryDate)) {
            return new Date(this.deliveryDate);
        } else {
            return new Date();
        }
    }


    handleLanguage = languageCode => {
        if (languageCode && languageCode == "ar") {
            return languageCode;
        }
        else {
            return languageCode = 'en';
        }

    }

    formatDate = (date) => {
        let day = date.getDate();
        let month = date.getMonth() + 1
        const year = date.getFullYear();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;

        return [year, month, day].join('-');
    }

    checkAnnualHolidays = (date) => {
        const dateToCompare = this.formatDate(date);
        let bool = false;
        if (this.props.holidayMaster) {
            const holidayList = JSON.parse(this.props.holidayMaster.holidayList);
            const dates = holidayList.map(holiday => holiday.date);
            bool = dates.includes(dateToCompare);
        }
        return bool;
    }

    checkIfDateDisable = (date) => {
        if(new Date(new Date().setHours(0,0,0,0)).getTime() == date.getTime()){   // disable current date from selection
            return true ;
        }
        else if (this.disabledDates.includes(date.getTime()))                     
        return true;
        if (isEmpty(this.props.holidayMaster)) {
            if (this.calendarType === 'Arabic') return date.getDay() == 5;
            if(this.calendarType=='US-SUN') return date.getDay() == 0;
        } else {
            let weekOffFlag = false;
            let annualOffFlag = false;
            let weekOffArray = [];
            let holiday;
            if (this.props.holidayMaster.weekOff.constructor === String) {
                weekOffArray = JSON.parse(this.props.holidayMaster.weekOff);
            } else if (this.props.holidayMaster.weekOff.constructor === Array) {
                weekOffArray = this.props.holidayMaster.weekOff;
            }
            weekOffFlag = weekOffArray.includes(date.getDay() + '') || (date.getDay()==0?weekOffArray.includes(7+''):weekOffArray.includes(date.getDay() + ""));
            annualOffFlag = this.checkAnnualHolidays(date);
            if((weekOffFlag || annualOffFlag) && 
                 (isEmpty(this.state.holiday["dates"])|| !this.state.holiday["dates"][date])){
                    holiday = this.state.holiday;
                    holiday["dates"][date] = date;
                    holiday["count"] = holiday["count"]+1;
                    this.setState({'holiday':this.state.holiday})
                
            }
            return weekOffFlag || annualOffFlag;
        }
    }

    isSlotDateDisabled = (date,dynamicSlotDates) => {
       const fullMonth =  (date.getMonth()) < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);               
       const fullDate = (date.getDate()+'').length == 2 ? date.getDate() : "0"+date.getDate();         
       const formattedDate = date.getFullYear()+"-"+fullMonth+"-"+fullDate;
       return !dynamicSlotDates.includes(formattedDate);
    }

    updateDateType =(key,value)=>{
        this.props.updateAttributeValue(this.props.index,key,value);
        this.props.updateValue(key, value);
        this.props.closeCalenderNewUi();
    }
    getCalendarDrawer = () => {
        const calendars = ['US', 'Arabic', 'Hebrew', 'ISO 8601','US-SUN'];
        if (calendars.indexOf(this.props.calendarType) > -1) {
            this.calendarType = cloneDeep(this.props.calendarType);
        }
        return (
            <Drawer
                anchor="bottom"
                open={this.state.calendar}
                onClose={this.calenderDrawer(false)}>
                <Calendar
                    locale={this.handleLanguage(this.props.language)}
                    tileClassName={this.getTileClassName}
                    tileDisabled={({ date }) => this.checkIfDateDisable(date)}
                    calendarType={this.calendarType.indexOf('US-SUN')!=-1?'US':this.calendarType}
                    onChange={(event) => {
                        let year = event.getFullYear();
                        let month = (event.getMonth() + 1) < 10 ? '0' + (event.getMonth() + 1) : (event.getMonth() + 1);
                        let date = event.getDate() < 10 ? '0' + event.getDate() : event.getDate()
                        let value = year + '-' + month + '-' + date;
                        this.updateDateType(this.props.attribute.key,value)
                    }}
                    
                    onClickDay={this.calenderDrawer(false)}
                    maxDate={this.maxDate && !isEmpty(this.maxDate) ? this.getMaxDateForCalendar() : ''}
                    minDate={this.minDate && !isEmpty(this.minDate) ? new Date(this.minDate) : ''}
                    value={!isEmpty(this.deliveryDate) ? new Date(this.deliveryDate) : new Date()}
                />
            </Drawer>
        )
    }

    getMaxDateForCalendar=()=>{
          if(this.maxDate && !isEmpty(this.maxDate)){
              if(this.state.holiday["count"]>0){
                  var date = new Date(this.maxDate);
                  date.setDate(date.getDate()+(this.state.holiday["count"]-1));
                  console.log("max date==",date);
                  return date;
          }  else {
              return new Date(this.maxDate);
          }
        }
    }

    getDateTimeDrawer = () => {
        return (
            <Drawer
                anchor="bottom"
                open={this.state.calenderTimeDrawer}
                onClose={event => {
                    this.setState({ 'dateTimeCalendar': !this.state.dateTimeCalendar })
                }}>
                <DateTimePicker
                    onChange={(event) => {
                    }}
                    value={this.props.attribute.value}
                    onClickDay={''}
                />
            </Drawer>
        )
    }

    getDynamicSlotDatesDrawer = (value,dynamicSlotDates,minRange,maxRange) => {
        return (
            <Drawer
                anchor="bottom"
                open={this.state.isSlotDrawerOpen}
                onClose={() => {
                    this.setState({ 'isSlotDrawerOpen': false })
                }}>
                 <Calendar
                    locale={this.handleLanguage(this.props.language)}
                    tileClassName={this.getTileClassName}
                    tileDisabled={({ date }) => this.isSlotDateDisabled(date,this.dynamicSlotDates)}
                    calendarType={this.calendarType}
                    value={!isEmpty(this.state.selectedSlotDate) ? new Date(this.state.selectedSlotDate) : !this.isSlotDateDisabled(new Date(this.props.attribute.value),this.dynamicSlotDates || [])? new Date(this.props.attribute.value) : null}          
                    onClickDay={()=>this.setState({isSlotDrawerOpen: false})}
                    onChange={(event) => {
                        let year = event.getFullYear();
                        let month = (event.getMonth() + 1) < 10 ? '0' + (event.getMonth() + 1) : (event.getMonth() + 1);
                        let date = event.getDate() < 10 ? '0' + event.getDate() : event.getDate()
                        let formattedDate = year + '-' + month + '-' + date;
                        this.getAvailableSlots(formattedDate,this.props.availableDatesFromCoordinator[formattedDate]);
                    }}
                  
                />
            </Drawer>
        )
    }
    
    getDynamicSlotTypeAttributeDrawerView = (attribute,dynamicSlotDates) => {
        let { key, label, value, required, minRange, maxRange, hidden } = attribute;
        if(this.props.attribute.value && !dynamicSlotDates.includes(this.props.attribute.value)){
            dynamicSlotDates.push(this.props.attribute.value);
        }
        return (
            
                <div className='monkey-survey-form-group-row'>
                <div className='monkey-survey-form-delivery-date'>
                <div className='monkey-survey-form-delivery-textarea'>
                <label className='monkey-survey-form-label'>{label}{required?<span style={{ color: 'red' }}>*</span>:null}</label>
                <div className='monkey-survey-form-inner-textarea'>
                    <TextField
                        id="standard-name"
                        margin="normal"
                        className="width-100"
                        required={required}
                        onPaste={e=>e.preventDefault()}
                        onClick={()=>{
                            this.dynamicSlotDates = dynamicSlotDates;
                            this.setState({ 'isSlotDrawerOpen': true })}
                        }
                        onChange={(event) =>
                            this.setState({'selectedSlotDate':event.target.value})
                        }
                        value={isEmpty(this.state.selectedSlotDate) ? convertFromStandardFormat(value, this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && this.props.trackingDetails.customerInteractionThemeSettingsDTO.dateFormat) :this.state.selectedSlotDate}
                        autoComplete='off'
                        InputLabelProps={{
                            style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                            className: (value && !isEmpty(value)) ? 'mui-label' : ''
                        }}
                        InputProps={{
                            style: { color: this.props.fontColor, fontFamily:`${this.fontType||"Roboto"},sans-serif`, fontSize: this.props.fontSize },
                        }}
                    />
                </div>
                <div>
                  {this.getSlotsDropdown(attribute)}
                </div>
                </div>
                </div>
                </div>

        )

        // return <div>
        //     <Button onClick={()=>this.setState({ 'isSlotDrawerOpen': true })}>Open Slot Window</Button>
        //     {this.state.selectedSlotDate ? <div>{"Selected Slot Date " +"    "+ this.state.selectedSlotDate}</div> : null}
        //     {this.getDynamicSlotDatesDrawer(value,dynamicSlotDates,minRange,maxRange)}
            // {this.getSlotsDropdown(attribute)}
        // </div>
    }

    getSlotsDropdown = (attribute) => {
        let { key, label, required, processAttributesValues, notEditable ,autoSave} = attribute;
        if ((!isEmpty(this.props.config3PLDataMap) && this.props.config3PLDataMap[this.props.form.title] && this.props.config3PLDataMap[this.props.form.title].fullDaySlotsEnabled) ||
              this.props.formDetailsMap[key].hide || 
                 !this.state.selectedSlotDate) {
            return null;
        }
        return (
            <div>
                <TextField
                    select
                    margin="normal"
                    className="width-100"
                    autoComplete='off'
                    required={required}
                    label="Select Slot"
                    onChange={(event) => {this.selectSlotValue(event.target.value);
                        autoSave?this.autoSavePufData():"";}}
                    InputLabelProps={{
                        style: { color: this.props.fontColor, fontFamily: this.props.fontFamily ? this.props.fontFamily : this.props.fontType, fontSize: this.props.fontSize },
                    }}
                    InputProps={{
                        style: { color: this.props.fontColor, fontFamily: this.props.fontFamily ? this.props.fontFamily : this.props.fontType, fontSize: this.props.fontSize },
                    }}
                    disabled={notEditable}
                >
                    {(this.props.slotList || []).map(slot => (
                        <MenuItem key={slot} value={slot} style={{ color: this.props.fontColor, fontFamily: this.props.fontFamily ? this.props.fontFamily : this.props.fontType, fontSize: this.props.fontSize }}
                        >
                            {slot.start || slot.startTime}-{slot.end || slot.endTime}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        )
    }


    async checkLocationType() {
        // this.props.disabled is used to check if any required address type attribute is empty or not, 
        //  if any address required attribute is empty then it will not show latlng value by default, 
        // so user can understand that something is missing in address part
        if (this.props.isAddressSubmitEnabled && !this.state.initialLocationValueEmpty && (!this.state.locationValue || isEmpty(this.state.locationValue)) && this.props.attribute && this.props.attribute.typeId && this.props.attribute.typeId == 52 &&
            this.props.attribute.required && this.props.attribute.value && !isEmpty(this.props.attribute.value)) {
            let latlngs = (this.props.attribute.value).split(',');
            var geocoder = new google.maps.Geocoder;
            var latlng = { lat: parseFloat(latlngs[0]), lng: parseFloat(latlngs[1]) };
            if(this.herePoweredAccount){
                let address = latlng.lat+","+latlng.lng;
                let results = "";
                if(results.items[0]){
                    this.setState({ 'locationValue': results.items[0].address.label })
                }
                else{
                    this.setState({ 'initialLocationValueEmpty': true }); 
                }
            }
            else{
                geocoder.geocode({ 'location': latlng }, function (results, status) {
                    if (status === 'OK' && results[0]) {
                        this.setState({ 'locationValue': results[0].formatted_address })
                    } else {
                        this.setState({ 'initialLocationValueEmpty': true });
                    }
                }.bind(this));
                }
         
        }
    }

      getstarclass = (key) => {
        const form = this.props.formDetailsMap[key];
        if (form.value && form.value == 1) return 'star-1';
        if (form.value && form.value == 2) return 'star-2';
        if (form.value && form.value == 3) return 'star-3';
        if (form.value && form.value == 4) return 'star-4';
        if (form.value && form.value == 5) return 'star-5';
      };
      getRadioTypeAttributesStars = (attribute) => {
        let { typeId, key, label, required, notEditable,processAttributesValues,autoSave } = attribute;
        if (this.props.formDetailsMap[key].hide) {
            return null;
        }
        return (
            <div className='monkey-survey-form-group-row'>
            <div className='monkey-survey-form-delivery-date'>
            <div className='monkey-survey-form-delivery-textarea'>
            <label className='monkey-survey-form-label'>{label}{required?<span style={{ color: 'red' }}>*</span>:null}</label>
                <div className={this.getstarclass(key)}>
                    <Rate
                    character="★"
                    style={{ fontSize: 42 }}
                    onChange={(value) => {this.props.updateValueSurveyFormWithValidation(key,value);
                        autoSave?this.autoSavePufData():""}}
                    value={this.props.formDetailsMap[key].value}
                    />
                </div>
                      
            </div>
            </div>
            </div>
        );
    }  
    getAttributes = () => {
        let { typeId } = this.props.attribute;
        if (typeId) {
            switch (JSON.stringify(typeId)) {
                case '1':
                case '2':
                case '6':
                case '28':
                case '29':
                case '30':
                case '27':
                case '31':
                case '52':
                    return (<div>{this.getSimpleTypeAttributes(this.props.attribute)}</div>);
                case '9':
                    if(this.props.attribute.attributeInputType=="Radio Button")
                    {return (<div>{this.getRadioButtonTypeAttributes(this.props.attribute)}</div>);}
                    else if(this.props.attribute.attributeInputType=="1-10 Scale")
                    {return (<div>{this.getRadioNumberTypeAttributes(this.props.attribute)}</div>);}
                    else if(this.props.attribute.attributeInputType=="Thumbs Up/Down")
                    {return (<div>{this.getRadioTypeAttributesThumbs(this.props.attribute)}</div>);}
                    else(this.props.attribute.attributeInputType=="Star Scale")
                    {return (<div>{this.getRadioTypeAttributesStars(this.props.attribute)}</div>);}
                case '8':
                    return (<div>{this.getCheckBoxMenuTypeAttribute(this.props.attribute)}</div>)
                case '10':
                    return (<div>{this.getDropDownSelectTypeAttribute(this.props.attribute)}</div>)
                case '3':
                    return (<div>{this.getDateTypeAttribute(this.props.attribute)}</div>);//todo
                case '4':
                    return (<div>{this.getDateTimeTypeAttribute(this.props.attribute)}</div>)//todo
                case '95':
                    return (this.props.form && this.props.form.dynamicSlotView?
                    <div>{this.getDynamicSlotTypeAttributeDrawerView(this.props.attribute,Object.keys(this.props.availableDatesFromCoordinator))}</div>:
                    <div>{this.getDynamicSlotTypeAttribute(this.props.attribute,Object.keys(this.props.availableDatesFromCoordinator))}</div>)//todo
                default:
                    return null;

            }
        }
    }
    checkIfDateIncludedInHolidayMaster=(date)=>{
        if (isEmpty(this.props.holidayMaster)) {
            if (this.calendarType === 'Arabic') return date.getDay() == 5;
            if(this.calendarType=='US-SUN') return date.getDay() == 0;
        } else {
            let weekOffFlag = false;
            let annualOffFlag = false;
            let weekOffArray = [];
            let holiday;
            if (this.props.holidayMaster.weekOff.constructor === String) {
                weekOffArray = JSON.parse(this.props.holidayMaster.weekOff);
            } else if (this.props.holidayMaster.weekOff.constructor === Array) {
                weekOffArray = this.props.holidayMaster.weekOff;
            }
            weekOffFlag = weekOffArray.includes(date.getDay() + '') || (date.getDay()==0?weekOffArray.includes(7+''):weekOffArray.includes(date.getDay() + ""));
            annualOffFlag = this.checkAnnualHolidays(date);
            if((weekOffFlag || annualOffFlag) && 
                 (isEmpty(this.state.holiday["dates"])|| !this.state.holiday["dates"][date])){
                    holiday = this.state.holiday;
                    holiday["dates"][date] = date;
                    holiday["count"] = holiday["count"]+1;
                    this.setState({'holiday':this.state.holiday})
                
            }
            return weekOffFlag || annualOffFlag;
        }
    }
    setDisabledDate =()=>{
        if(this.props.attribute?.beforeDays || this.props.attribute?.afterDays){
           let beforeDays = this.props.attribute?.beforeDays || 0;
           let afterDays = this.props.attribute?.afterDays || 0;
          this.disabledDates=[]
          let currentDate = new Date();
          let defaultDate = new Date(new Date().setHours(0,0,0,0));
          if(this.props?.attribute?.value){
           currentDate=new Date(new Date(this.props.attribute.value).setHours(0,0,0,0));
           defaultDate=new Date(new Date(this.props.attribute.value).setHours(0,0,0,0));
          }
          let currentDate3 = cloneDeep(currentDate);
          let currentDate4 = cloneDeep(currentDate);
          let currentDate2= cloneDeep(currentDate);
           let startDate= new Date(currentDate.setDate(currentDate.getDate() - parseInt(beforeDays)));
           let endDate = new Date(currentDate2.setDate(currentDate2.getDate() + parseInt(afterDays)));
         while(startDate <= currentDate4){
           if(defaultDate.getTime() != currentDate4.getTime()){
             this.disabledDates.push( new Date(new Date(currentDate4).setHours(0,0,0,0)).getTime());
             if(this.checkIfDateIncludedInHolidayMaster(currentDate4)){
               startDate.setDate(startDate.getDate() -1);
             }
           } 
             
             currentDate4.setDate(currentDate4.getDate() -1);
         }
         while(currentDate3 <= endDate){
           if(defaultDate.getTime() != currentDate3.getTime()){
             this.disabledDates.push( new Date(new Date(currentDate3).setHours(0,0,0,0)).getTime());
             if(this.checkIfDateIncludedInHolidayMaster(currentDate3)){
               endDate.setDate(endDate.getDate() +1);
           }
           } 
             
             currentDate3.setDate(currentDate3.getDate() +1);
         }
           // while(startDate <= endDate){
           //   if(defaultDate.getTime() != startDate.getTime()){
           //     this.disabledDates.push( new Date(new Date(startDate).setHours(0,0,0,0)).getTime());
           //   } 
           //     if(this.checkIfDateIncludedInHolidayMaster(startDate)){
           //         endDate.setDate(endDate.getDate() +1);
           //     }
           //     startDate.setDate(startDate.getDate() +1);
           // }
       }
       }
    render() {
        if(isEmpty(this.disabledDates)){
            this.setDisabledDate();
        }
        this.checkLocationType();
        return (
            <div>
                {this.getAttributes()}
                {this.getCalendarDrawer()}
                {this.getDateTimeDrawer()}
                {this.getDynamicSlotDatesDrawer()}
            </div>

        )
    }

}
function mapStateToProps(state) {
    return {
      autoSavePufFlag: state.customerInteractionReducer.autoSavePufFlag,
    };
  }
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...CustomerInteractionAction }, dispatch)
    }
  }  
export default connect(mapStateToProps,mapDispatchToProps)(SurveyMonkeyFormAttributes);
