const hindiStrings = {
    YourShipmentHasBeenDelivered:'आपका शिपमेंट डिलीवर हो चुका है',
DeliveredBy:'के द्वारा डिलीवर किया गया',
RateYourExperience:'आपके अनुभव को रेटिंग दें',
ThankYouForYourValuableFeedback:'आपके मूल्यवान फीडबैक के लिए धन्यवाद',
YourFeedbackWillContinuallyHelpUsImproveOurServices:'आपका फीडबैक हमारी सेवाओं को सुधारने में निरंतर मदद करेगा',
DeliveryFailed:'डिलीवरी विफल रही',
AddDeliveryPreferences:'डिलीवरी प्राथमिकता जोड़ें',
YourDeliveryPreferenceAreSavedSuccessFully:'आपके डिलीवरी प्राथमिकता को सफलतापूर्वक सेव कर लिया गया है',
WeAreUnableToTrackYourShipmentRightNow:'हम अभी आपके शिपमेंट को ट्रैक करने में असमर्थ हैं',
PleaseUpdateLocationInsideCircle:'कृपया सर्कल के अंदर लोकेशन अपडेट करें',
Ok:'ठीक है',
Update:'अपडेट',
PickCurrentLocation:'वर्तमान लोकेशन चुनें',
SearchFor:'के लिए खोजें',
ThisSchedulingLinkHasExpired:'यह शेड्यूलिंग लिंक समाप्त हो गया है',
WeWillShareANewLinkWithYouShortly:'हम आपसे थोड़े समय में नई लिंक साझा करेंगे',
UhHo:'ओह!',
NeedHelp:'मदद की ज़रूरत है?',
CallSupport:'कॉल सपोर्ट',
EmailSupport:'ईमेल सपोर्ट',
DeliveryAt:'पर डिलीवरी',
DeliveredAt: '... पर डिलीवर किया गया',
ContactNo:'कॉन्टेक्ट नंबर',
TrackOnMap:'मैप पर ट्रैक करें',
MessageToExecutive:'अधिकारी को मैसेज दें',
Cancel:'कैंसल',
Send:'भेजें',
Executive:'अधिकारी',
Comments:'कमेंट (वैकल्पिक)',
SubmitFeedback:'फीडबैक दें',
TrackingNo:'ट्रैकिंग नंबर',
Details:'विवरण',
Characters:'कैरेक्टर्स',
/******** New Strings Addded *****/
Updating:'अपडेट हो रहा है....',
OopsSomethingWentWrongPleaseTryAfterSomeTime:'ओह! कुछ गड़बड़ है, कृपया कुछ समय बाद कोशिश करें',
NotListedAboveSendACustomMessage:'ऊपर लिस्टेड नहीं है, कस्टम मैसेज भेजें',
ChangeDeliveryLocation:'डिलीवरी लोकेशन बदलें',
AttemptedBy:'इसके द्वारा कोशिश की गई',
DeliveredTo:'इन्हें डिलीवर किया गया',
SendMessage:'मैसेज भेजें',
VIEW_DETAILS:'विवरण देखें',
SAVE_LOCATION:'लोकेशन सेव करें',
LOCATION:'लोकेशन',
SEARCH_ADDRESS:'पता ढूंढें',
/****New Strings Dec Month*/
OpenHours:'खुलने का समय',
Note:'नोट',
Address:'पता',
Nearest: 'निकटतम',
ContactNumber:'संपर्क का नंबर',
FAQS:'अक्सर पूछे जाने वाले प्रश्न',
HowManyTimesCanIChangeMyPickUpPoint: 'मैं कितनी बार अपना पिकअप पॉइंट बदल सकता/ सकती हूँ?',
YouCanChangeItOnly: 'आप इसे केवल एक बार बदल सकते हैं',
GoBack:'पीछे जाएँ',
SetAsPickupStore:'पिकअप स्टोर के रूप में सेट करें',
MapView:'मैप व्यू',
SearchForPickupPointNearYou:'अपने आस-पास पिकअप पॉइंट सर्च करें',
Arriving:'पहुंच रहा है',
LiveTrackingWillStart:'लाइव ट्रैकिंग शुरू होगी',
DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'रास्ते में आते ही ड्राइवर का ट्रैकर सक्रिय हो जाएगा',
TrackerTimeline:'ट्रैकर टाइमलाइन',
PM:'पीएम',
AM:'एएम',
TodayAt:'आज का समय',
TomorrowAt:'कल का समय',
Today:'आज',
Tomorrow:'कल',
On:'पर',
In:'में',
Soon:'जल्दी ही',
Min:'मिनट',
Mins:'मिनट',
Items:'आइटम',
ViewDetails: 'विवरण देखें',
DeliveryInformation:'डिलीवरी जानकारी',
TrackingHistory:'ट्रैकिंग इतिहास',
StayConnected:'जुड़े रहें',
YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'आप पिछले कुछ मिनट में पहले ही एक भुगतान करने की कोशिश कर चुके हैं. क्या आप पिछले लेनदेन को रद्द करना चाहते हैं',
No: 'नहीं',
yes:'हां',
YourPackageWillBeDeliveredBy : 'इस समय तक आपका पैकेज डिलीवर कर दिया जाएगा',
OrderDetails: 'ऑर्डर विवरण',
OrderInformation:'ऑर्डर जानकारी',
LastAttempted:'अंतिम प्रयास किया गया ',
DeliveryBy:'डिलीवरी... द्वारा',
Pickuppointisupdated:'पिकअप प्वाइंट अपडेट किया गया',
MsgToFePageHeader:'डिलीवरी संबंधी निर्देश भेजें',
MsgToFeSupportingText:'आप ड्राइवर को क्या कहना चाहते हैं?',
AddYourMsgHere:'अपना संदेश यहाँ दर्ज करें',
InstructionsSentSuccessfully:'निर्देश सफलतापूर्वक भेजा जा चुका है',
SomethingWentWrongPlsTryAgain:'कुछ गड़बड़ हो गई है, कृपया दुबारा प्रयास करें',
times:"बार",
TrackMovementInRealTime:"गतिविधी को रियल टाइम में ट्रैक करें",
Hours:"hours",
Hour:"hour",
WriteYourFeedbackOptional:"अपनी प्रतिक्रिया लिखें (वैकल्पिक)",
ReturnOrder: "Return Order",
TheLinkYouAreTryingToOpenHasExpired: "आप जिस लिंक को खोलने का प्रयास कर रहे हैं उसकी समय सीमा समाप्त हो गई है",
SelectAnAvailableTimeSlot:"Select an available time slot",
January:"JANUARY",
February:"FEBRUARY",
March :"MARCH",
April:"APRIL",
May:"MAY",
June:"JUNE",
July:"JULY",
August:"AUGUST",
September:"SEPTEMBER",
October:"OCTOBER",
November:"NOVEMBER",
December:"DECEMBER",
Monday:"MONDAY",
Tuesday:"TUESDAY",
Wednesday:"WEDNESDAY",
Thursday:"THURSDAY",
Friday:"FRIDAY",
Saturday:"SATURDAY",
Sunday:"SUNDAY",
ViewTimings: "View Timings",
HideTimings: "Hide Timings",
PleaseAnswerAllMandatoryQuestions:"कृपया सभी अनिवार्य प्रश्नों के उत्तर दें",
HelpLineLink: 'Helpline Link',
ShipmentOf: "शिपमेंट%में से",
OtherShipments: "अन्य शिपमेंट",
OrderInformation:"Order Information",
OR:"या",
TrackShipment:" ट्रैक शिपमेंट",
TrackingDescription:"अपने शिपमेंट का विवरण प्राप्त करने के लिए नीचे दिए गए विवरण दर्ज करें",
Continue:"जारी रखना",
InvalidCredentials:"अमान्य क्रेडेंशियल्स। कृपया फिर से प्रयास करें।",
ITEM: 'वस्तु',
ITEMS: 'वस्तु(एं)',
OrderNo:"आदेश संख्या",
CurrentStatus:"वर्तमान स्थिति",
PickupTime:"पिकअप का समय",
StoreLocation:"Store Location",
LeaveBy:"Leave By",
toReachByScheduledTime:"to reach by scheduled time",
ShareThisLink:"Share this Link",
ETAtime:"ETA time",
ParkingInstructions:"Parking instructions",
PR1_heading:"Find a Spot",
PR2_heading:"Park Safely",
PR3_heading:"Secure and Exit",
PR1_description:"Look for a marked parking space; ensure it's not restricted or reserved.",
PR2_description:" Align your vehicle within the lines and leave enough space for others.",
PR3_description:"Turn off the engine, engage the handbrake, lock the car, and take your belongings.",
willBringYourOrderShortly:"will bring your order shortly.",
PODImage:"POD Image",
CopiedtoClipboard:"Copied to Clipboard",
}
export default hindiStrings;
