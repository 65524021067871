const thaiStrings={
    YourShipmentHasBeenDelivered:'พัสดุของคุณได้รับการจัดส่งแล้ว',
    DeliveredBy:'ส่ง',
    RateYourExperience:'ให้คะแนนประสบการณ์ของคุณ',
    ThankYouForYourValuableFeedback:'ขอบคุณสำหรับคำแนะนำที่มีค่าของคุณ',
    YourFeedbackWillContinuallyHelpUsImproveOurServices:'คำแนะนำของคุณจะช่วยให้เราปรับปรุงบริการของเราอย่างต่อเนื่อง',
    DeliveryFailed:'การส่งล้มเหลว',
    AddDeliveryPreferences:'เพิ่มการจัดส่งที่ต้องการ',
    YourDeliveryPreferenceAreSavedSuccessFully:'การจัดส่งที่คุณต้องการได้รับการบันทึกเรียบร้อยแล้ว',
    WeAreUnableToTrackYourShipmentRightNow:'เราไม่สามารถติดตามพัสดุของคุณได้ในขณะนี้',
    PleaseUpdateLocationInsideCircle:'โปรดอัปเดตตำแหน่งที่ตั้งภายในวงกลม',
    Ok:'ตกลง',
    Update:'อัปเดต',
    PickCurrentLocation:'เลือกตำแหน่งที่ตั้งปัจจุบัน',
    SearchFor:'ค้นหา',
    ThisSchedulingLinkHasExpired:'ลิงก์กำหนดเวลานี้หมดอายุแล้ว',
    WeWillShareANewLinkWithYouShortly:'เราจะให้ลิงก์ใหม่กับคุณในไม่ช้า',
    UhHo:'อ๊ะ!',
    NeedHelp:'ต้องการความช่วยเหลือใช่ไหม',
    CallSupport:'โทรหาฝ่ายสนับสนุน',
    EmailSupport:'อีเมลฝ่ายสนับสนุน',
    DeliveryAt:'ส่งที่',
    DeliveredAt: 'จัดส่งเมื่อ',
    ContactNo:'เบอร์โทรติดต่อ',
    TrackOnMap:'ติดตามบนแผนที่',
    MessageToExecutive:'ส่งข้อความถึงผู้บริหาร',
    Cancel:'ยกเลิก',
    Send:'ส่ง',
    Executive:'ผู้บริหาร',
    Comments:'ข้อเสนอแนะ (ถ้ามี)',
    SubmitFeedback:'ส่งข้อเสนอแนะ',
    TrackingNo:'หมายเลขพัสดุ',
    Details:'รายละเอียด',
    Characters:'ตัวอักษร',
    Updating:'กำลังอัปเดต.....    ',
    OopsSomethingWentWrongPleaseTryAfterSomeTime:'ขออภัย! เกิดข้อผิดพลาดโปรดลองอีกสักครู่    ',
    NotListedAboveSendACustomMessage:'ไม่อยู่ในรายการด้านบน ส่งข้อความที่กำหนดเอง    ',
    ChangeDeliveryLocation:'เปลี่ยนสถานที่จัดส่ง',
    AttemptedBy:'พยายามโดย    ',
    DeliveredTo:'    ส่งถึงที่    ',
    SendMessage:'ส่งข้อความ    ',
    VIEW_DETAILS:'ดูรายละเอียด    ',
    SAVE_LOCATION:'บันทึกสถานที่',
    LOCATION:'สถานที่',
    SEARCH_ADDRESS:'ค้นหาที่อยู่    ',
    OpenHours:'    เวลาเปิดทำการ    ',
    Note:'บันทึก',
    Address:'ที่อยู่',
    Nearest: 'ใกล้ที่สุด',
    ContactNumber:'เบอร์ติดต่อ',
    FAQS:'คำถามที่พบบ่อย',
    HowManyTimesCanIChangeMyPickUpPoint: 'เปลี่ยนจุดนัดรับได้กี่ครั้ง',
    YouCanChangeItOnly: 'คุณสามารถเปลี่ยนได้เท่านั้น    ',
    GoBack:'กลับไป',
    SetAsPickupStore:'ตั้งเป็น Pickup Store',
    MapView:'มุมมองแผนที่    ',
    SearchForPickupPointNearYou:'ค้นหาจุดรับสินค้าที่อยู่ใกล้คุณ    ',
    Arriving:'มาถึง',
    LiveTrackingWillStart:'การติดตามแบบสดจะเริ่มขึ้น',
    DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'ตัวติดตามของคนขับจะเปิดใช้งานเมื่อพวกเขามาถึง    ',
    TrackerTimeline:'ติดตามไทม์ไลน์',
    PM:'PM',
    AM:'AM',
    TodayAt:'วันนี้เวลา    ',
    TomorrowAt:'พรุ่งนี้เวลา',
    Today:'วันนี้    ',
    Tomorrow:'พรุ่งนี้    ',
    On:'บน    ',
    In:'ใน',
    Soon:'เร็ว ๆ นี้    ',
    Min:'นาที',
    Mins:'นาที',
    Items:'รายการ    ',
    ViewDetails: 'ดูรายละเอียด',
    DeliveryInformation:'ข้อมูลการจัดส่ง    ',
    TrackingHistory:'ประวัติการติดตาม     ',
    StayConnected:'เชื่อมต่ออยู่เสมอ    ',
    YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'คุณได้พยายามชำระเงินแล้วในไม่กี่นาทีที่ผ่านมา คุณต้องการยกเลิกธุรกรรมก่อนหน้านี้หรือไม่    ',
    No: 'ไม่',
    yes:'ใช่    ',
    YourPackageWillBeDeliveredBy : 'พัสดุของคุณจะถูกจัดส่งโดย    ',
    OrderDetails: 'รายละเอียดการสั่งซื้อ '   ,
    OrderInformation:'ข้อมูลการสั่งซื้อ    ',
    LastAttempted:'ดำเนินการล่าสุด',
    DeliveryBy:'จัดส่งโดย',
    Pickuppointisupdated:'จุดรับสินค้าอัพเดทแล้ว',
    MsgToFePageHeader:'ส่งคำแนะนำในการจัดส่ง',
    MsgToFeSupportingText:'คุณต้องการแจ้งอะไรกับผู้ขับขี่',
    AddYourMsgHere:'เพิ่มข้อความของคุณที่นี่',
    InstructionsSentSuccessfully:'จัดส่งคำแนะนำเสร็จสิ้น',
    SomethingWentWrongPlsTryAgain:'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
    times:"ครั้ง",
    TrackMovementInRealTime:"ติดตามการเคลื่อนไหวแบบเรียลไทม์",
    Hours:"hours",
    Hour:"hour",
    WriteYourFeedbackOptional:"เขียนความคิดเห็นของคุณ (ไม่บังคับ)",
    ReturnOrder: "Return Order",
    TheLinkYouAreTryingToOpenHasExpired: "ลิงก์ที่คุณพยายามเปิดหมดอายุแล้ว",
    SelectAnAvailableTimeSlot:"Select an available time slot",
    January:"JANUARY",
    February:"FEBRUARY",
    March :"MARCH",
    April:"APRIL",
    May:"MAY",
    June:"JUNE",
    July:"JULY",
    August:"AUGUST",
    September:"SEPTEMBER",
    October:"OCTOBER",
    November:"NOVEMBER",
    December:"DECEMBER",
    Monday:"MONDAY",
    Tuesday:"TUESDAY",
    Wednesday:"WEDNESDAY",
    Thursday:"THURSDAY",
    Friday:"FRIDAY",
    Saturday:"SATURDAY",
    Sunday:"SUNDAY",
    ViewTimings: "View Timings",
    HideTimings: "Hide Timings",
    PleaseAnswerAllMandatoryQuestions:"กรุณาตอบคำถามที่จำเป็นทั้งหมด",
    HelpLineLink: 'Helpline Link',
    ShipmentOf: "Shehena%ya",
    OtherShipments: "การจัดส่งอื่น ๆ",
    OrderInformation:"Order Information",
    OR:"หรือ",
    TrackShipment:"ติดตามการจัดส่ง",
    TrackingDescription:"กรอกรายละเอียดด้านล่างเพื่อรับรายละเอียดการจัดส่งของคุณ",
    Continue:"ดำเนินการต่อ",
    InvalidCredentials:"ข้อมูลรับรองไม่ถูกต้อง โปรดลองอีกครั้ง",
    ITEM: 'รายการ',
    ITEMS: 'รายการ(ต่างๆ)',
    OrderNo:"หมายเลขคำสั่งซื้อ",
    CurrentStatus:"สถานะปัจจุบัน",
PickupTime:"เวลารับสินค้า",
StoreLocation:"Store Location",
LeaveBy:"Leave By",
toReachByScheduledTime:"to reach by scheduled time",
ShareThisLink:"Share this Link",
ETAtime:"ETA time",
ParkingInstructions:"Parking instructions",
PR1_heading:"Find a Spot",
PR2_heading:"Park Safely",
PR3_heading:"Secure and Exit",
PR1_description:"Look for a marked parking space; ensure it's not restricted or reserved.",
PR2_description:" Align your vehicle within the lines and leave enough space for others.",
PR3_description:"Turn off the engine, engage the handbrake, lock the car, and take your belongings.",
willBringYourOrderShortly:"will bring your order shortly.",
PODImage:"POD Image",
CopiedtoClipboard:"Copied to Clipboard",
}
export default thaiStrings;