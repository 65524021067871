import React from "react";
import Header from "../../../components/Header";
import CurbsideOrderShareLink from "../components/CurbsideOrderShareLink";
import SocialMedia from "../../../components/SocialMedia";
import CurbsidePickupConstants from "../constants/CurbsidePickupConstants";
import { Box, Modal } from "@material-ui/core";
import OrderDetails from "./OrderDetails";
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty } from "lodash";
import CurbsideNeedHelpDrawer from "../components/CurbsideNeedHelpDrawer";
import { Card, Typography } from "antd";
import EmbedTracking from "../../LiveTracking/EmbedTracking";
import { formatDate, formateText, formatTimeRange } from "./config";
import { CarOutlined, EyeOutlined, ShoppingOutlined, WarningOutlined } from "@ant-design/icons";

const { Text } = Typography

class CurbSideDesktopView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showNeedHelpDrawer: false,
            orderDetailsModalOpen: false,
            parkingInstructionsModal: false
        }
    }
    setHelpModel = (show) => {
        this.setState({ showNeedHelpDrawer: show });
    }
    getOrderDetailsModalView = () => {
        return (
            <Modal
                open={this.state.orderDetailsModalOpen}
                onClose={() => {
                    this.setState({ orderDetailsModalOpen: false })
                }}
                className="order-details-modal"
            >
                <OrderDetails
                    {...this.props}
                    backScreenHandler={() => {
                        this.setState({ orderDetailsModalOpen: false })
                    }}
                    customBackIcon={() => {
                        return (<CloseIcon fontSize="large" />)
                    }}

                />

            </Modal>
        )
    }
    getNeedHelp = () => {
        let helpSectionText, callSupport, emailSupport;
        if (this.props.trackingDetails && this.props.trackingDetails.type === "CURBSIDE_PICKUP" && this.props.trackingDetails.customerInteractionThemeSettingsDTO && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO)) {
            helpSectionText = this.props.trackingDetails.customerInteractionThemeSettingsDTO.helpSectionText;
            helpSectionText = helpSectionText ? helpSectionText : containerConstants.formatString(containerConstants.NeedHelp);
            callSupport = this.props.trackingDetails.customerInteractionThemeSettingsDTO.supportContactNumber;
            emailSupport = this.props.trackingDetails.customerInteractionThemeSettingsDTO.supportEmail;
        }

        if (callSupport || emailSupport) {
            return {
                helpSectionText,
                callSupport,
                emailSupport
            }
        }
        return undefined;
    }

    changeScreenToPickupSchedule = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.TIME_SLOT, this.changeScreenToPreCheckIn);
    }

    changeScreenToPreCheckIn = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.PRE_CHECK_IN, null);
    }

    changeScreenToCheckIn = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.CHECK_IN, this.changeScreenToPreCheckIn);
    }

    changeScreenToOrderDetails = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.ORDER_DETAILS, this.changeScreenToPreCheckIn);
    }

    getPickupDataWithSlot = () => {
        const pickupData = { label: "", date: "", time: "" };
        const preCheckInData = this.props.curbsidePickupData?.preCheckInData;
        if (preCheckInData && preCheckInData.pickupScheduleDateTimeAttribute) {
            if (preCheckInData.pickupScheduleDateTimeLabel) {
                pickupData["label"] = preCheckInData?.pickupScheduleDateTimeLabel || "";
            }
            pickupData["date"] = formatDate(preCheckInData.pickupScheduleDateTimeAttribute);
            pickupData["time"] = formatTimeRange(preCheckInData.pickupScheduleDateTimeAttribute, preCheckInData.pickupScheduleDateTimeMin, preCheckInData.pickupScheduleDateTimeMax);
            return pickupData;
        }
        return null;
    }
    handleButtonClick = (buttonConfig) => {
        if (buttonConfig.action == "FORM") {
            let processUpdateForm = JSON.parse(this.props.customerInteractionServiceDTO.processUpdateForm);
            const index = processUpdateForm.formList.findIndex(puf => puf.customerInteractionFormId == buttonConfig.form);
            let attribute = {}
            if(buttonConfig?.nextStatusCode){
            attribute.key = this.props.curbsidePickupData?.statusAttributeCode;
            attribute.type = "String"
            attribute.typeId = 1;
            attribute.hide = true;
            attribute.value = buttonConfig?.nextStatusCode;
            }
            this.props.handleButtonAction(index,attribute);
        }
        else {
            let referenceNumber = this.props.customerInteractionServiceDTO?.referenceNumber;
            let processMasterCode = ""
            let processUpdateForm = this.props.customerInteractionServiceDTO?.processUpdateForm;
            if (processUpdateForm) {
                let parsedProcessUpdateForm = JSON.parse(processUpdateForm);
                processMasterCode = parsedProcessUpdateForm.processMasterCode;
            }
            let form = [];
            let attribute = {}
            attribute.key = this.props.curbsidePickupData?.statusAttributeCode;
            attribute.type = "String"
            attribute.typeId = 1
            attribute.value = buttonConfig?.nextStatusCode;
            form.push(attribute);
            this.props.updateFormData(form, processMasterCode, referenceNumber, this.props.curbsidePickupUrl, "form", "", "", "", "", "", "", "", "", "", "", "CURBSIDE_PICKUP", "", false);

        }

    }
    getActions = () => {
        if (this.props.screenType == "TRACKING") {
            return [
                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', gap: 6, padding: '0 10px', color: '#000' }} onClick={() => this.setState({ orderDetailsModalOpen: true })}><EyeOutlined />{containerConstants.formatString(containerConstants.ViewDetails)}</div>,
                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', gap: 6, padding: '0 10px', color: '#000' }} onClick={() => { this.setState({ parkingInstructionsModal: true }) }}><CarOutlined />{containerConstants.formatString(containerConstants.ParkingInstructions)}</div>,
            ];
        }
        return [
            <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', gap: 6, padding: '0 10px', color: '#000' }} onClick={() => this.setState({ orderDetailsModalOpen: true })}><EyeOutlined /> {containerConstants.formatString(containerConstants.ViewDetails)}</div>,
        ];
    }

    renderButtons = () => {
        return this.props.buttonsConfig.map((buttonConfig) => {
            if(!isEmpty(buttonConfig.label)){
                return (
                    <button
                    className="submit-button mb5"
                    onClick={() => { this.handleButtonClick(buttonConfig) }}
                    >{buttonConfig.label}</button>
                )
            }
        })

    }
    isShowExecutiveMsg = ()=>{
        if(this.props.buttonsConfig?.at(0)?.label){
            return false;
        }
        return true;
    }

    renderPickupData = () => {
        const pickupData = this.getPickupDataWithSlot();
        if (pickupData) {
            return (
                <div style={{ padding:"10px"}}>
                    <Text strong>{containerConstants.formatString(containerConstants.PickupTime)}</Text>
                    <br />
                    <Text>{pickupData.time}, {pickupData.date}</Text>
                </div>
            )
        }
    }

    renderParkingInstructions = () => {
        let parkingInstructions = `${"1. <b>" + containerConstants.formatString(containerConstants.PR1_heading) + "</b>: " +
            containerConstants.formatString(containerConstants.PR1_description) + "\n" +
            "2. <b>" + containerConstants.formatString(containerConstants.PR2_heading) + "</b>: " +
            containerConstants.formatString(containerConstants.PR2_description) + "\n" +
            "3. <b>" + containerConstants.formatString(containerConstants.PR3_heading) + "</b>: " +
            containerConstants.formatString(containerConstants.PR3_description)}`;

        if (isEmpty(parkingInstructions)) {
            return;
        }
        let primaryColor = this.props.curbsidePickupData?.customerInteractionServiceDTO?.customerInteractionThemeSettingsDTO?.primaryBgColor || '#0000';
        let parkingInstructionsList = parkingInstructions.split("\n");
        return (
            <div className="c-s-parking-instruction c-s-common">
                <h2 style={{ color: primaryColor, borderColor: primaryColor }}>{containerConstants.formatString(containerConstants.ParkingInstructions)}<WarningOutlined /></h2>
                {parkingInstructionsList.map((instructions) => {
                    let formatedInstruction = instructions.replace(/\s+/g, " ").trim();
                    return <p style={{ padding: "4px 0" }}>{formateText(formatedInstruction)}</p>
                })}
            </div>
        )
    }
    getTimeToLeave = () => {
        const dateString = this.props.curbsidePickupData?.preCheckInData?.pickupScheduleDateTimeAttribute;
        const travelDuration = this.props.curbsidePickupData?.travelDuration;
        if (dateString && travelDuration) {
            const match =travelDuration.match(/(?:(\d+)\s*hours?)?\s*(?:(\d+)\s*mins?)?/i);
            const hours = match[1] ? parseInt(match[1], 10) : 0;
            const minutes = match[2] ? parseInt(match[2], 10) : 0; 
            let date = new Date(dateString);
            date.setHours((date.getHours() - hours));
            date.setMinutes(date.getMinutes() - minutes);            
            return date.toTimeString().substring(0,5);
        }
        return null;
    }

    renderStatusValue = () => {
        const themeData = this.props.curbsidePickupData?.customerInteractionServiceDTO?.customerInteractionThemeSettingsDTO;
        return <div
            style={{ borderRadius: 20, padding: '5px 10px', fontSize: 12, backgroundColor: (themeData?.ctaColorWhite ? "#ffffff" : themeData?.secondaryBgColor), color: (themeData?.ctaFontColor ? themeData?.ctaFontColor : (themeData?.primaryTextColor ? themeData?.primaryTextColor : themeData?.primaryBgColor)) }}
        >
            {this.props.customerInteractionServiceDTO?.status || ''}
        </div>
    }
    openGoogleMap=()=>{
        const lat = this.props.trackingDetails.jobLat || 0;
        const lng = this.props.trackingDetails.jobLng || 0;
        const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
        window.open(googleMapsUrl, "_blank");
    }

    render() {
        const needHelp = this.getNeedHelp();
        const timeToLeave = this.getTimeToLeave();
        return (
            <div className="curbside-web-header">
                <Header
                    logo={this.props.companyLogo}
                    curbsidePickup={true}
                    primaryBgColor={"primaryBgColor"}
                    primaryTextColor={"primaryTextColor"}
                    fontSize={"fontSize"}
                    fontFamily={"fontFamily"}
                    deviceType={false}
                    needHelp={this.props.showNeedHelp}
                    setHelpModel={() => this.props.setHelpModel(true)}
                    helpSectionText={this.props.needHelp && this.props.needHelp.helpSectionText}
                />

                {this.props.showNeedHelp &&
                    <CurbsideNeedHelpDrawer
                        {...this.props}
                    />
                }

                <div className="curbside-web-wrapper">
                    {this.state.orderDetailsModalOpen && this.getOrderDetailsModalView()}
                    <div className="curbside-web-container">
                        <div className="curbside-web-outer-wrap">
                            <div className="curbside-web-middle-header">

                                <div className="curbside-web-left-sidebar">
                                    <Card style={{ borderRadius: 8, width: "100%", overflow: 'hidden', borderColor: '#dcdcdc' }}
                                        bodyStyle={{ padding: 0 }}
                                        actions={this.getActions()}>
                                        <header style={{ borderBottom: '1px solid #d3d3d3', padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ borderRadius: "50%", padding: "10px", marginRight: "10px", width: "40px", height: "40px", background: "#2929291c", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <ShoppingOutlined />
                                                </div>
                                                <div>
                                                    <Text strong>{containerConstants.formatString(containerConstants.OrderNo)}</Text>
                                                    <br />
                                                    <Text>{this.props.curbsidePickupData.storeDetailsData && this.props.curbsidePickupData.storeDetailsData.orderNumber}</Text>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    this.props.customerInteractionServiceDTO?.status &&
                                                    this.renderStatusValue()
                                                }

                                            </div>
                                        </header>
                                        <div>
                                            {
                                                this.props.curbsidePickupData?.storeDetailsData?.addressLine1 &&
                                                <div style={{ padding:"10px"}}>
                                                    <Text strong>{containerConstants.formatString(containerConstants.StoreLocation)}</Text>
                                                    <br />
                                                    <Text>{this.props.curbsidePickupData?.storeDetailsData?.addressLine1}</Text>
                                                </div>
                                            }
                                            {
                                                this.props.screenType == "PRE_CHECK_IN" && this.renderPickupData()
                                            }
                                            {
                                                this.props.screenType == "PRE_CHECK_IN" && timeToLeave &&
                                                <div style={{ padding:"10px"}}>
                                                    <Text strong>{containerConstants.formatString(containerConstants.LeaveBy)}</Text>
                                                    <br />
                                                    <Text>{timeToLeave} {containerConstants.formatString(containerConstants.toReachByScheduledTime)}</Text>
                                                </div>
                                            }
                                            {
                                                this.props.screenType == "TRACKING" &&
                                                this.props.curbsidePickupData?.travelDuration && <div style={{ padding:"10px"}}>
                                                    <Text strong>{containerConstants.formatString(containerConstants.ETAtime)}</Text>
                                                    <br />
                                                    <Text>{this.props.curbsidePickupData?.travelDuration}</Text>
                                                </div>
                                            }
                                            {
                                                (this.props.screenType == "POST_CHECK_IN" && this.isShowExecutiveMsg()) &&
                                                <div style={{ textAlign: "center", margin: "10px" }}>
                                                    <Text style={{ fontSize: 14, fontWeight: 400 }}>{this.props.curbsidePickupData?.postCheckInData?.deliveryStaff || containerConstants.formatString(containerConstants.Executive)} {containerConstants.formatString(containerConstants.willBringYourOrderShortly)}</Text>
                                                </div>
                                            }

                                        </div>

                                    </Card>
                                    {
                                        this.props.buttonsConfig &&
                                        <div className="c-s-submit-btn">
                                            {this.renderButtons()}
                                        </div>
                                    }
                                </div>
                                <div className="curbside-web-right-sidebar">
                                    <div className="c-w-map" onClick={this.openGoogleMap}>
                                        <EmbedTracking />
                                    </div>
                                    <CurbsideOrderShareLink
                                        curbsidePickupData={this.props.curbsidePickupData}
                                        curbsidePickupUrl={this.props.curbsidePickupUrl}></CurbsideOrderShareLink>
                                </div>
                            </div>
                        </div>
                        <Modal
                            open={this.state.parkingInstructionsModal}
                            onClose={() => this.setState({ parkingInstructionsModal: false })}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                            >
                                {this.renderParkingInstructions()}
                            </Box>
                        </Modal>
                    </div>


                    {this.props.layoutScreen && !this.props.layoutScreen.hideSocialMedia &&
                        <SocialMedia
                            curbsidePickup={true}
                            layoutScreen={this.props.layoutScreen}
                        />
                    }


                </div>
            </div >
        )
    }
}



export default CurbSideDesktopView;

