import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { isEmpty } from 'loadsh'



function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function MessageSnackBar(props) {
  const [open, setOpen] = React.useState(true);
  const [transition, setTransition] = React.useState(undefined);

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setOpen(true);
  };


  const handleClose = (event,reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.updateScreen();
    setOpen(false);
  };

  return (
    <div className="message-snack-bar">
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        autoHideDuration={null}
        message={isEmpty(props.message)?containerConstants.formatString(containerConstants.YourDeliveryPreferenceAreSavedSuccessFully):props.message}
        key={transition ? transition.name : ''}
        action={props.isEnableAction?
          <Button style={{border:"1px solid white", borderRadius:"8px", color:"white"}} size="small"  onClick={handleClose}>
            OK
          </Button>:<></>
        }
      />
    </div>
  );
}