import React from "react";
import PickupSchedule from "./PickupSchedule";
import CurbsidePickupConstants from "../constants/CurbsidePickupConstants";
import PreCheckIn from "./PreCheckIn";
import { processUpdateFormService } from "../../../services/ProcessUpdateFormService";
import OrderDetails from "./OrderDetails";
import CheckIn from "./CheckIn";
import PostCheckIn from "./PostCheckIn";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FeedbackV3 from "../../FeedbackV3";
import CurbsideFeedback from "./CurbsideFeedback";
import { isEmpty } from "lodash";
import CurbSideMobileView from "./CurbSideMobileView";
import ProcessUpdateForm from "../../ProcessUpdateForm";
import CurbSideDesktopView from "./CurbSideDesktopView";


class MainScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            marketingAssetsData: null,
            backScreenHandler: null,
            showPuf:false,
            formIndex:null,
            updateAttribute:{}
        }
    }

    async componentDidMount() {
        if (
            this.props.curbsidePickupData.otherDetailsData &&
            this.props.curbsidePickupData.otherDetailsData.marketingAssets
        ) {
            let marketingAssetsData = await processUpdateFormService.getMarketingAssets(this.props.curbsidePickupUrl, true);
            this.setState({ marketingAssetsData });
        }
    }

    handleButtonAction=(index, updateAttribute)=>{
       this.props.changeScreen("PUF");
       this.setState({updateAttribute:updateAttribute,formIndex:index});
    
    }
    handBackButton=()=>{
        this.props.changeScreen(this.props.curbsidePickupData.screen);
    }

    renderPickupScheduleScreen = (backScreenHandler) => {
        return (
            <PickupSchedule
                {...this.props}
                formIndex={0}
                isMobileType = {this.props.deviceType=="mobile"?true:false}
                isBackAllowed={backScreenHandler ? true : false}
                backScreenHandler={backScreenHandler}
                isCurbsidePickup={true}
            />
        )
    }

    renderPreCheckInScreen = (screen) => {
        if(this.props.deviceType =="mobile"){
            return (
                <CurbSideMobileView
                {...this.props}
                marketingAssetsData={this.state.marketingAssetsData}
                changeScreen={this.changeScreen}
                layoutScreen={this.getSocialMediaLayoutData()}
                orderCount={this.getOrderCount()}
                screenType={this.props.screenType}
                updateFormData={this.props.updateFormData}
                handleButtonAction={this.handleButtonAction}
                customerInteractionServiceDTO={this.props.curbsidePickupData?.customerInteractionServiceDTO}
                buttonsConfig={this.props.curbsidePickupData.buttonsConfig}
                
                />
            )
        }
        return (
            <CurbSideDesktopView
            {...this.props}
            marketingAssetsData={this.state.marketingAssetsData}
            changeScreen={this.changeScreen}
            layoutScreen={this.getSocialMediaLayoutData()}
            orderCount={this.getOrderCount()}
            screenType={this.props.screenType}
            updateFormData={this.props.updateFormData}
            handleButtonAction={this.handleButtonAction}
            customerInteractionServiceDTO={this.props.curbsidePickupData?.customerInteractionServiceDTO}
            buttonsConfig={this.props.curbsidePickupData.buttonsConfig}/>
        )
    }

    renderOrderDetailsScreen = (backScreenHandler) => {
        return (
            <OrderDetails
                {...this.props}
                backScreenHandler={backScreenHandler}
                customBackIcon={() => {
                    return (<ChevronLeftIcon className="cb-text-primary" fontSize="large" />)
                }}
            />
        )
    }

    renderCheckInScreen = (backScreenHandler) => {
        return (
            <CheckIn
                {...this.props}
                formIndex={1}
                isMobileType = {this.props.deviceType=="mobile" ? true:false}
                isBackAllowed={backScreenHandler ? true : false}
                backScreenHandler={backScreenHandler}
                isCurbsidePickup={true}
            />
        )
    }

    renderPostCheckIn = (backScreenHandler) => {
        return (
            <PostCheckIn
                {...this.props}
                marketingAssetsData={this.state.marketingAssetsData}
                changeScreen={this.changeScreen}
                layoutScreen={this.getSocialMediaLayoutData()}
                orderCount={this.getOrderCount()}
            />
        )
    }

    renderFeedback = (backScreenHandler) => {
        return (
            <CurbsideFeedback
                {...this.props}
                layoutScreen={this.getSocialMediaLayoutData()}
            />
        )
    }
    renderProcessUpdateForm = (backScreenHandler) => {
        return (
            <ProcessUpdateForm
                {...this.props}
                layoutScreen={this.getSocialMediaLayoutData()}
                formIndex={this.state.formIndex}
                back={true}
                backScreen={this.handBackButton}
                hideSubHeader={true}
                isMobileType={true}
                curbsidePickup={true}
                updateAttribute={this.state.updateAttribute}
            />
        )
    }

    changeScreen = (screenType, backScreenHandler) => {
        this.props.changeScreen(screenType);
        this.setState({ backScreenHandler });
    }

    renderScreen = (screenType, backScreenHandler) => {

        switch (screenType) {
            case CurbsidePickupConstants.curbsidePageFlowTypes.PUF:
                return this.renderProcessUpdateForm(backScreenHandler);
            case CurbsidePickupConstants.curbsidePageFlowTypes.ORDER_DETAILS:
                return this.renderOrderDetailsScreen(backScreenHandler);
            case CurbsidePickupConstants.curbsidePageFlowTypes.FEEDBACK:
                return this.renderFeedback(backScreenHandler);
            default:
                return this.renderPreCheckInScreen(backScreenHandler);
        }
    }

    getSocialMediaLayoutData = () => {
        let curbsidePickupData = this.props.curbsidePickupData;
        if (curbsidePickupData &&
            curbsidePickupData.customerInteractionServiceDTO &&
            curbsidePickupData.customerInteractionServiceDTO.customerInteractionThemeSettingsDTO) {
            let themeData = curbsidePickupData.customerInteractionServiceDTO.customerInteractionThemeSettingsDTO;
            return {
                facebook: themeData.facebook,
                twitter: themeData.twitter,
                youtube: themeData.youtube,
                insta: themeData.instagram,
                tiktok: themeData.tiktok,
                linkedIn: themeData.linkedIn,
                pinterest: themeData.pinterest,
                hideSocialMedia: !themeData.facebook && !themeData.twitter && !themeData.youtube && !themeData.instagram && !themeData.tiktok && !themeData.linkedIn && !themeData.pinterest
            }
        }
    }

    getOrderCount = () => {
        let count;
        if (this.props.curbsidePickupData && this.props.curbsidePickupData.orderDetailsData && this.props.curbsidePickupData.orderDetailsData.orderDetailAttributeList && !isEmpty(this.props.curbsidePickupData.orderDetailsData.orderDetailAttributeList)) {
            const childAttrLengths = this.props.curbsidePickupData.orderDetailsData.orderDetailAttributeList
                .filter(order => order.childAttributeArray && !isEmpty(order.childAttributeArray))
                .map(order => order.childAttributeArray.length);
            if (childAttrLengths && childAttrLengths.length > 0) {
                count = childAttrLengths[0];
            }
        }
        return count;
    }

    render() {
        return this.renderScreen(this.props.screenType, this.handBackButton);
    }
}

export default MainScreen;