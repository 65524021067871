export const curbsidePageFlowTypes = {
    TIME_SLOT: "TIME_SLOT",
    PRE_CHECK_IN: "PRE_CHECK_IN",
    CHECK_IN: "CHECK_IN",
    ORDER_DETAILS: "ORDER_DETAILS",
    POST_CHECK_IN: "POST_CHECK_IN",
    FEEDBACK: "FEEDBACK",
    PUF:"PUF",
}

export default { curbsidePageFlowTypes };